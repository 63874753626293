import { motion } from "framer-motion"; 
import Paragraph from "../../atoms/Paragraph";
import SwiperCard from "../../slider/SwiperCards";
import { DevisButton } from "../../atoms/DevisButton";
import { CheckText } from "../../organisms/CheckText";
import { campaign } from "../../../utils/myData";

export const AboutHero = () => {
    

    return (
        <div className="container w-10/12 min-h-[70vh] flex flex-col gap-10 lg:py-20 py-10 px-2">
            <div  className=" 2xl:h-[calc(100vh-24rem)] justify-between items-stretch relative flex lg:flex-row flex-col gap-10">
                <motion.div className={`lg:w-1/3 grow w-full flex flex-col gap-10`}
                    variants={{
                        hidden: { opacity: 0 },
                        visible: { opacity: 1 },
                    }}
                    whileInView="visible"
                >
                    <div className="lg:max-w-4xl">
                        <motion.h2
                            className="2xl:text-7xl xl:text-4xl text-2xl font-black text-slate-900 lg:pr-8 leading-relaxed z-20"
                            initial={{opcity: 0, translateY:55}}
                            whileInView={{opacity: 1, translateY:0}}
                            
                            transition={{type: "spring", stiffness: 200, damping: 50}}
                        >
                            Vos campagnes créatives ne génèrent pas l'engagement escompté ?
                        </motion.h2>
                    </div>
                
                    
                    <div className="flex flex-col divide-y divide-slate-300">
                        <div className="pb-8">
                            <Paragraph 
                                content="Chez Mobali-Lab, nous orchestrons des expériences créatives data-driven où chaque élément sert un objectif :" 
                                customStyle="lg:max-w-2xl py-4 "
                            />

                            <ul className="flex flex-col gap-4 pr-10">
                                <CheckText textCustom="Audit minutieux: " content="Identification précise de votre audience réelle grâce à la data (exit les suppositions)." />
                                <CheckText textCustom="Scénarios hybrides: " content="Synergie entre influenceurs pertinents, contenus engageants et activation communautaire." />
                                <CheckText textCustom="Optimisation continue: " content="Tests A/B émotionnels et ajustements en temps réel pour maximiser l’impact." />
                            </ul>
                        </div>

                        <div className="pt-4">
                            <Paragraph 
                                content="Notre mantra ? 'L'émotion qui convertit' : des campagnes où la créativité sert le ROI, avec un suivi granularité (CPE, taux de mémorisation, sentiment de marque)." 
                                customStyle="lg:max-w-2xl"
                                size="Small"
                                color="text-slate-500"
                            />
                        </div>
                        
                        

                    </div>

                    <div className="lg:max-w-4xl ">
                        <DevisButton label="Lancer une campagne impactante" />
                    </div>
                
                </motion.div>

                <div className="lg:w-1/5 grow w-10/12 sm:h-[70vh] mx-auto h-full">
                    <SwiperCard data={campaign} type />
                </div>
            </div>
        </div>
    )
}