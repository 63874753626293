import { useContext, useEffect } from "react";
import { DataCompaign } from "../../../data/Data";
import { CategoryCompaign } from "../components/organism/CategoryCompaign"
import { UniqueCompaign } from "../components/organism/UniqueCompaign";

import { getCategoryTitle } from "../utils/getCategoryTitle"
import { motion } from "framer-motion";

import {LoadingContext} from "../../../context/loadingContext";
import {LoadingPage} from "../../../ui/LoadingPage";

export const Campaign = () => {
    const { loading, startLoading } = useContext(LoadingContext);
    const latestCampaign = DataCompaign[DataCompaign.length -1];

    console.log(latestCampaign);
    
    useEffect(() => {
            startLoading();
        }, [startLoading]);

    if (loading) return <LoadingPage isText="Campagnes en cours ..."/>;

    return (
        <motion.section 
            className="bg-gradient-to-b from-30% from-bg-slate-900 to-black w-full"
            initial={{ opacity:0 }}
            animate={{ opacity: 1 }}  
            
            transition={{ type:"spring", duration:.5, ease:"easeIn" }}    
        >
            {/* Latest campaign */}
            <UniqueCompaign data={latestCampaign} />
            <hr className="border-t-[1px] border-slate-50/30 lg:w-1/4 w-1/3 mx-auto" />
            {/* Campaign categories */}
            <article className="min-h-screen py-10 flex flex-col gap-20 items-center">
                {["collabs", "events", "music", "gaming", "lifestyle", "buzz", "stories", "divers"].map( item =>
                    <CategoryCompaign latestCompaignId={latestCampaign?.id} category={item} categoryTitle={getCategoryTitle(item)} />
                )}           
            </article>
            
        </motion.section>
    )
}