import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { VideoCustom } from "../../organisms/VideoCustom";

export const StickyVideo = () => {
    const videoRef = useRef(null);
    const containerVideoRef = useRef(null);

    const { scrollYProgress } = useScroll({
        target: containerVideoRef,
        offset: ["start end", "end start"],
    });

    const opacity = useTransform(scrollYProgress, [1, 0.7, 0], [1, 1, 0]);

    return (
        <motion.div
            ref={containerVideoRef}
            className="sticky top-0 h-screen w-full"
            style={{ opacity }}
        >
            <VideoCustom 
                videoRef={videoRef}
                videoName="triggervideo"
            />
        </motion.div>
    );
};