// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cards';

// import required modules
import { EffectCards } from 'swiper/modules';
import Image from '../atoms/Image';

import { motion } from 'framer-motion';

const SwiperCard = ({ data, type }) => {

    const swiperSettings = {
        effect: 'cards',
        grabCursor: true,
        modules: [EffectCards],
        className: `mySwiper h-full w-full`,
        speed: 400,
    };

    return (
        <Swiper {...swiperSettings}>
            {data?.map((item, index) => (
                <SwiperSlide key={item.id}>
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: 0.8, ease: "easeInOut" }}

                        className="relative h-full w-full"
                        title={item.slug}
                    >
                        {type ? (
                            <Image alt={`campagne ${item}`} image={item} custWidth="w-full h-full object-cover"/> 
                        ) : (
                            <Image
                                custWidth={`h-full w-full`}
                                image={`${process.env.REACT_APP_API_URL}/uploads/${item.picture}`}
                                url
                            />
                        )}
                    </motion.div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SwiperCard;