import { motion, useMotionTemplate, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

export const ParallaxCont = ({ className, subClassName, content, subtext, start, end, item, index }) => {
    const ref = useRef(null);

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: [`${start}px end`, `end ${end * -2}px`],
    });

    const opacity = useTransform(scrollYProgress, [0.75, 1], [1, 0]);
    const y = useTransform(scrollYProgress, [0, 1], [start, end]);
    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.9]);
    const transform = useMotionTemplate`translateY(${y}px) scale(${scale})`;

    return (
        <motion.p
            ref={ref}
            className={`${className} 2xl:text-7xl xl:text-5xl lg:text-4xl text-2xl leading-snug`}
            style={{ transform, opacity }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
        >
            {content ? content : item ? item.text : "Vide"}
            {subtext && <span className={`block mt-4 ${subClassName}`}>{subtext}</span>}
        </motion.p>
    );
};