import { dataServices, infoServices } from "../utils/myData";
import { CoverParallax } from "../ui/organisms/CoverParallax";
import { useContext, useEffect } from "react";
import {LoadingContext} from "../context/loadingContext";
import { LoadingPage } from "../ui/LoadingPage";
import { motion } from "framer-motion";
import HeadCustom from "../HeadCustom";
import { HorizontalScrollCarousel } from "../ui/organisms/HorizontalScrollCarousel";

const Services = () => {
    const { loading, startLoading } = useContext(LoadingContext);

    useEffect(() => {
        startLoading();
    }, [startLoading]);

    if (loading) return <LoadingPage isText="Service en cours..." />;
    
    return (
        <>
            <HeadCustom headtitle={"Mobali-Lab Services"} />
            <motion.article 
        
                initial={{ opacity:0 }}
                animate={{ opacity:1 }}
                transition={{ type:"spring", duration:.5, ease:"easeIn" }}

                className="flex flex-col"
            >
                <HorizontalScrollCarousel
                    infos={infoServices} 
                    type="services" 
                    light 
                    data={dataServices}
                />

                <CoverParallax 
                    order={true} 
                    cover="services" 
                    listText={infoServices.listText} 
                />

            </motion.article>
        </>
        
    );
};

export default Services;
