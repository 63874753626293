import { motion } from "framer-motion";
import {useNavigate} from "react-router-dom";

const Item = ({ data, key, url }) => {
    
    const navigate = useNavigate();

    const handleOpenDrawer = (slug) => {
        if (!slug) return;

        return navigate(`/campagnes/${slug}`)
    }

    return (
        <div
            key={key}
            className="2xl:h-[700px] lg:h-72 xl:h-96 h-[550px] relative 2xl:w-[450px] xl:w-72 lg:w-52 w-full cursor-pointer hover:brightness-75 transition ease-linear group flex flex-col gap-4"

            onClick={ () => {
                handleOpenDrawer(data.slug);
            }}
        >
            <div className="order-last">
                <h3 className="text-slate-50 lg:text-md text-sm font-medium">{data.title}</h3>
            </div>

            <div className="xl:w-72 lg:w-52 2xl:h-[700px] lg:h-72 xl:h-96 h-[550px] 2xl:w-[450px] overflow-hidden">
                <motion.figure 
                    className={`xl:w-72 lg:w-52 2xl:h-[700px] lg:h-72 xl:h-96 h-[550px] 2xl:w-[450px] `}
                    whileHover={{ scale:1.02 }}    
                    whileTap={{ scale:0.945 }}

                    transition={{ type:"spring", damping: 10, stiffness:100 }}
                >
                    <img
                        src={`${ url ? `./uploads/${data.nameImage}.webp` : `./uploads/project/${data.mainImage}.webp`}`}
                        alt={data.title}
                        className={`w-full h-full object-cover rounded-sm`}
                    />
                </motion.figure>
            </div>

        </div>
    )
};

export default Item;
