import { useNavigate } from "react-router-dom";
import { MdOutlineClose } from 'react-icons/md';
import { motion } from "framer-motion";

export const BackButton = ({ color, bgcolor, path, type }) => {
    const navigate = useNavigate();

    const onLink = () => {
        return navigate(`${path ? `/${path}` : "/"}`)
    } 

    return (
        <motion.button
            className={`z-10 2xl:absolute fixed left-0 2xl:top-10 top-0 h-10 w-10 2xl:rounded-full rounded-br-full flex items-center justify-center ${bgcolor ? bgcolor : "bg-slate-50"} ${color ? color : "text-orange-400"}`}
            onClick={onLink}
            whileHover={{ 
                scale: 1.045
            }}
            whileTap={{
                scale: 0.945,
            }}

            type={type}
        >
            <span style={{color: color,}}
            ><MdOutlineClose /></span>
        </motion.button>
    )
}