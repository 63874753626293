export const Avatar = ({ avatar, size }) => {
    return (
        <div 
            id="avatar"
            style={{
                height:size.height,
                width:size.width
            }}
        >
            <figure
                style={{
                    height:size.height,
                    width:size.width
                }}
            >
                <img src={`/uploads/${avatar}.webp`} alt="avatar" />
            </figure>
        </div>
    )
}