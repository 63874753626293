import {motion} from "framer-motion";
import {Navigate, useParams} from "react-router-dom";
import useFetchBySlug from "../../../../hooks/useFetchBySlug";
import Heading from "../../../../ui/atoms/Heading";
import Image from "../../../../ui/atoms/Image";

const DisplayItem = () => {

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/posts/`;
    const { slug } = useParams();

    const { data, isLoading, error } = useFetchBySlug(apiUrl + slug);

    if (error) {
        return <Navigate to="/pageintrouvable" replace="true" />;
    }

    return (
        <>
            {   isLoading ? 
                    <span className="border border-slate-300 rounded-sm p-2 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 text-xs text-slate-500">
                        Chargement...
                    </span>
                :
                <motion.div
                    initial={{ opacity: 0, y:25 }}
                    animate={{ opacity: 1,y:0 }}
                    transition={{ type:"spring", duration: .5, delay:.2, ease: "ease-in" }}
                    className="flex flex-col items-center rounded-md overflow-hidden">
                    { data &&
                        <>
                            {/* Content */}
                            <div className={`max-w-[550px] order-last px-4 py-6`}>
                                <Heading content={data.title} typeHeading="h2" size="text-xl" color="text-slate-50"/>
                                <div
                                    className="text-slate-300 mt-2"
                                    dangerouslySetInnerHTML={{
                                        __html: data?.content
                                    }}
                                />
                            </div>

                            {/* Main Image */}
                            <div className={`lg:w-96 w-full relative`}>
                                <Image
                                    image={`${process.env.REACT_APP_API_URL}/uploads/${data?.picture}`}
                                    alt={data?.title}
                                    url
                                    custWidth={"lg:w-96 w-full h-[550px]"}
                                    rounded={false}
                                />
                            </div>
                        </>
                    }
                </motion.div>
            }</>
    );
};

export default DisplayItem;
