import { motion } from "framer-motion";
import { StickyVideo } from "../atoms/StickyVideo";
import { infoAboutImpact } from "../../../utils/myData";
import { ParallaxCont } from "../atoms/ParallaxCont";
import { useState, useEffect } from "react";
import useMobile from "../../../hooks/useMobile";
export const ScrollTriggeredVideo = () => {

    const [sectionHeight, setSectionHeight] = useState(1700);
    
    const isMobile = useMobile(1440)

    useEffect(() => {
        if (isMobile) {
            setSectionHeight(1200);
        }
    }, [isMobile]);

    return (
        <motion.div 
            className="relative w-full"
            style={{ height: `calc(${sectionHeight}px + 100vh)`}}
        >
            <StickyVideo />

            {infoAboutImpact.map((item, index) => (
                <ParallaxCont 
                    key={item.id}
                    start={item.animation.start}
                    end={item.animation.end}
                    className="mx-auto text-slate-900 xl:w-6/12 lg:w-1/2 w-11/12 font-bold text-center italic"
                    item={item}
                    index={index}
                />
                
            ))}
        </motion.div>
    );
};