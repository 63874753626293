export const getGradientPosition = (direction, height="h-96", width="lg:w-52 w-32") => {
    switch (direction) {
      case "to-b":
        return `top-0 left-0 w-full ${height}`; // Top gradient
      case "to-t":
        return `bottom-0 left-0 w-full ${height}`; // Bottom gradient
      case "to-r":
        return `top-0 left-0 ${width} h-full`; // Left gradient
      case "to-l":
        return `top-0 right-0 ${width} h-full`; // Right gradient
      default:
        return "";
    }
};