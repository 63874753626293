import { motion } from "framer-motion";
import React, { useState } from "react";
import { FiEye, FiEyeOff, FiLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import {Button} from "../../../ui/button/Button";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prevShow) => !prevShow);
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Effectuer la requête d'authentification
    fetch(`${process.env.REACT_APP_API_URL}/api/users/signin/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "api-x-key": process.env.REACT_API_KEY,
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Enregistrer le token dans le stockage local
        localStorage.setItem("token", data.token);

        window.location.replace("/mk-admin");
      })
      .catch((error) => {
        console.error("Erreur:", error);
      });
  };

  return (
    <article id="login">
      <div className="wrapper">
        <form onSubmit={handleSubmit} className="forms">
          <h1 className="title_h1">Se connecter</h1>
          <div className={`form-group ${isFocus ? "focused" : ""}`}>
            <label htmlFor="email"></label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              required
              placeholder="Email"
            />
          </div>
          <div className={`form-group ${isFocus ? "focused" : ""}`}>
            <label htmlFor="password"></label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={handlePasswordChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              required
              placeholder="Mot de passe"
            />
            <motion.button
              className="showpassword"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", duration: 0.5, ease: "linear" }}
              onClick={handleShowPassword}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </motion.button>
          </div>
          <Button
            namebtn="Se connecter"
            color="#272727"
            width={1}
            bgcolor="#fafafa"
            type="submit"
            iconlog
          />
        </form>
        <Link to={"/"} title="mblcorps.com" className="text">
          {" "}
          <span>
            <FiLink />
          </span>{" "}
          Revenir sur le site{" "}
        </Link>
      </div>
    </article>
  );
};
