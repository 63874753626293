import ActusList from "./ActusList";
import {Link} from "react-router-dom";

const Blog = () => {

    return (
        <article className={`z-10 min-h-screen overflow-hidden relative`}>
            <div className={`xl:absolute top-4 z-10 flex items-center justify-start rounded-sm h-10 xl:w-96 md:w-6/12 w-11/12 m-auto xl:transform xl:left-1/2 xl:-translate-x-1/2`}>
                <ul className="flex gap-4 text-slate-100 relative w-full text-sm">
                    <li className="w-1/4">
                        <Link to={`/`} className={`flex gap-2 items-center`}>Accueil</Link>
                    </li>
                    <li className="w-1/4 text-slate-400">
                        <p>Actualités</p>
                    </li>
                    <span className={`h-1 w-full bg-slate-400/20 absolute left-0 xl:-bottom-2 -bottom-4  rounded-full`}><span className={`h-1 w-20 bg-orange-400 block rounded-full`}></span></span>
                </ul>
            </div>

            <ActusList/>
        </article>
    )
}

export default Blog;