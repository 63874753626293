import { Link } from "react-router-dom";
import { TitleFooter } from "../ui/text/TitleFooter";
import { List } from "../ui/list/List";
import { footerData } from "../utils/myData";
import { ParallaxText } from "../ui/organisms/TextVelocity";

const Footer = () => {
  return (
    <footer className="min-h-fit 2xl:pb-24 bg-zinc-900 relative">
      <ParallaxText />
      <div className="wrapper flex flex-col lg:min-h-96 min-h-screen">
        {/* Top Footer */}
        <div className="w-full h-72 grow py-10">
          <ul className="flex lg:flex-row flex-col items-stretch w-full h-full  gap-10">
            { footerData.map( item => 
              <li key={item?.lists.id} className="lg:w-1/4 w-full flex flex-col gap-4">
                <TitleFooter title={item.title} />
                <List data={item.lists}/>
              </li>
            )}
          </ul>
        </div>
        <hr className="border-slate-500"/>
        {/* Bootom Footer */}
        <div className="h-20 relative py-4 lg:divide-x divide-slate-700 lg:gap-0 gap-4  flex lg:flex-row flex-col lg:items-center">
          <p className="pr-4 2xl:text-xl text-sm text-slate-400"> <sup>©</sup> 2025 Mobali-Lab</p>
          <p className="text-slate-400 2xl:text-md text-xs lg:pl-4 ">Développé par <Link to="https://www.linkedin.com/in/serge-lema-140953206/" target="_blank" className="underline hover:text-orange-400">Serge Lema</Link></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
