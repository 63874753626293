export const getCategoryTitle = (category) => {
  switch (category) {
    case "trending":
      return "En Tendance";
    case "exclusive":
      return "Exclusivités";
    case "events":
      return "Moments Forts";
    case "music":
      return "Hits & Vibes";
    case "gaming":
      return "Game On";
    case "lifestyle":
      return "Lifestyle & Mode";
    case "collabs":
      return "Collabs Épiques";
    case "buzz":
      return "Buzz du Moment";
    case "stories":
      return "Histoires Inspirantes";
    case "divers":
      return "Autres Univers";
    default:
      return "";
  }
};