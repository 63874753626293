import React, { useEffect } from "react";
import { silderLogo } from "../../utils/myData";

export const SliderLogo = () => {
  

  useEffect(() => {
    // Si l'utilisateur n'a pas activé la réduction de mouvement
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      const scrollers = document.querySelectorAll(".scroller");

      scrollers.forEach((scroller) => {
        // Ajouter l'attribut `data-animated="true"`
        scroller.setAttribute("data-animated", true);

        // Récupérer le contenu de `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        if (scrollerInner) {
          const scrollerContent = Array.from(scrollerInner.children);

          // Dupliquer chaque élément et l'ajouter dans `.scroller-inner`
          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        }
      });
    }
  }, []);

  return (
    <div className="scroller mx-auto lg:h-52" data-speed="slow">
      <ul className="scroller__inner flex gap-32 items-center justify-center">
        {silderLogo?.map((item, idx) => (
          <li key={idx}>
            <div className="2xl:w-52 lg:w-24 w-14 bg-transparent">
              <figure className="2xl:w-52 lg:w-24 w-14">
                <img
                  src={item}
                  alt={`Logo ${idx + 1}`}
                  className="w-full h-full object-contain"
                />
              </figure>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
