import { motion, useMotionTemplate, useScroll, useTransform } from "framer-motion"
import { useEffect, useState } from "react";
import  useMobile from "../../../hooks/useMobile"
export const CenterImage = ({ cover }) => {
    // Scroll progress pour le conteneur spécifique
    const [coverResp, setMobileResp] = useState(cover)
    const isMobile = useMobile();


    useEffect(() => {
        if (isMobile) {
            setMobileResp(cover + "-mobile")
        } else {
            setMobileResp(cover+ "-mobile")
        }
    }, [coverResp, isMobile, cover])

    const { scrollYProgress } = useScroll({
        offset: ["end end", "start start"],
    });

    const clip1 = useTransform(scrollYProgress, [1,0], [25, 0]);
    const clip2 = useTransform(scrollYProgress, [1, 0], [75, 100]);

    const clipPath = useMotionTemplate`polygon(${clip1}% ${clip1}%, ${clip2}% ${clip1}%, ${clip2}% ${clip2}%, ${clip1}% ${clip2}%)`;

    const backgroundSize = useTransform(
        scrollYProgress,
        [0,1+500],
        ["100%", "0%"]
    );
    const opacity = useTransform(
        scrollYProgress,
        [1,0.8,0],
        [1,1,0]
    );

    return (
        <motion.div
            className="sticky top-0 h-screen w-full"
            style={{
                clipPath,
                backgroundSize:backgroundSize,
                opacity,
                backgroundImage:`${cover ? `url(uploads/${coverResp}.webp)` : `url(uploads/studio-open.webp)`}`,
                backgroundPosition:"center",
                backgroundRepeat:"no-repeat",
                filter: "brightness(0.6)",
            }}
            viewport={{ amount: 1 }}
        >
        </motion.div>
    )
}