import React, { useContext, useEffect } from 'react';
import ArticleComp from "../../../ui/ArticleComp";
import Stepper from "../components/organisms/Stepper";
import {BackButton} from "../../../ui/button/BackButton";
import { LoadingPage } from "../../../ui/LoadingPage";
import { LoadingContext } from '../../../context/loadingContext';
const Devis = () => {

    const { loading, startLoading } = useContext(LoadingContext);

    useEffect   (() => {
        startLoading();
    }, [startLoading]);
    if (loading) return <LoadingPage isText="Devis gratuit"/>
    
    return (
        <ArticleComp backgroundColor="bg-slate-50" height="min-h-screen">
            <div className="lg:h-screen pt-40 flex items-start justify-center">
                <Stepper />
            </div>
            <BackButton color="text-slate-50" bgcolor="bg-black"/>
        </ArticleComp>
    );
};

export default Devis;
