import { Outlet } from "react-router-dom";

import Footer from "../partials/Footer";
import Header from "../partials/Header";
import {useContext} from "react";
import {LoadingContext} from "../context/loadingContext";

const LayoutPage = () => {
    const { loading } = useContext(LoadingContext);
  return (
    <>
      { !loading && <Header/> }
      <main>
        <Outlet />
      </main>
      { !loading && <Footer/> }
    </>
  );
};

export default LayoutPage;
