import { useMediaQuery } from "react-responsive";
import BannerVideoWebm from "../../assets/video/banner_video.webm";
import Paragraph from "../atoms/Paragraph";
import {motion, useScroll, useTransform} from "framer-motion";
import { useRef } from "react";

const Banner = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 868px)" });

    const refTarget = useRef(null);

    const { scrollYProgress } = useScroll({
        target: refTarget,
        offset: ["end end", "end start"]
    });

    const opacity = useTransform(scrollYProgress , [0, 0.5], [1,0] );

    return (
      <motion.article 
        ref={refTarget}
        className="relative h-screen z-30"
    >
        <motion.div
            style={{
                width: "100%",
                height: isMobile ? "100vh" : "100vh",
                left: "0",
                top: "0",
                backgroundImage: isMobile ? "url(./uploads/banner2.webp)" : "none",
                backgroundSize:"cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                filter: "brightness(80%)",

                opacity
            }}
        >
              {!isMobile && (
                  <div className="lg:h-screen">
                      <video
                          className="banner_video"
                          muted
                          autoPlay
                          loop
                          playsInline
                          style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              left: "0",
                              top: "0",
                          }}
                      >
                          <source src={BannerVideoWebm} type="video/webm"/>
                      </video>
                  </div>
              )}
        </motion.div>

        <span className="h-32 bg-gradient-to-b from-black absolute top-0 w-full"></span>

        <div className="2xl:min-h-60 min-h-48 bg-black absolute w-full bottom-0 left-0 px-10">
            <div className="container mx-auto relative py-10 text-slate-200 flex flex-col lg:gap-0 gap-4">
                <div className="lg:absolute 2xl:-top-20 -top-8 flex flex-col gap-4">
                    <h2 className={`text-slate-300 2xl:text-8xl lg:text-6xl font-bold text-4xl`}>
                        <motion.span
                            className="block"
                            initial={{ opacity: 0, translateX: 25 }}
                            animate={{ opacity: 1, translateX:0 }}
                            transition={{ type:"spring", stiffness:100, damping:15, delay:.2}}
                        >Une campagne.</motion.span>
                        <motion.span
                            className="lg:block"
                            initial={{ opacity: 0, translateX: -25 }}
                            animate={{ opacity: 1, translateX:0 }}
                            transition={{ type:"spring", stiffness:100, damping:15, delay:.3}}
                        >Votre impact.</motion.span>
                    </h2>
                </div>
                
                <motion.div
                    className="lg:w-1/4 w-full lg:absolute lg:right-0"
                    initial={{ opacity: 0, translateY: 5 }}
                    animate={{ opacity: 1, translateY:0 }}
                    transition={{ type:"spring", stiffness:100, damping:15, delay:.4}}
                >
                    <Paragraph content="Transformez votre vision en réalité avec des stratégies sur mesure et efficaces" color="text-slate-300" size="Medium"/>
                </motion.div>
            </div>

        </div>

      </motion.article>
    );
};

export default Banner;
