import {Link} from "react-router-dom";
import ArticleComp from "../ArticleComp";
import Ping from "../atoms/Ping";
import Heading from "../atoms/Heading";


const ErrorPage = () => {
  return (
      <ArticleComp height="h-screen" >
        <div className="h-screen">
          <div className="flex gap-2 flex-col items-center justify-center absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 transform lg:max-w-96 w-full">
            <Heading content="Vous avez découvert une page mystérieuse !" typeHeading="h2" size="small" />
            <p className="text-sm text-slate-200 text-center">Revenez à la page <Link to="/" className="font-semibold text-orange-400 text-xs">d'accueil </Link>pour continuer l'exploration du site.</p>
          </div>
          <Ping/>
        </div>
      </ArticleComp>
  )
}

export default ErrorPage;