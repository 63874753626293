import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export const DevisButton = ({ label = "Concrétisons vos idées", outlined = false, size="h-14" }) => {
    const navigate = useNavigate();
    return (
        <button 
            onClick={() => navigate("/creerundevis")} 
            className={`md:w-fit w-full 2xl:h-24 2xl:px-10 flex items-center overflow-hidden lg:text-lg md:text-base text-xs ${outlined ? "border border-orange-400 text-orange-400" : "bg-gradient-to-r from-30% from-orange-400 to-rose-500 text-white"} group ${size} hover:text-white transition-all duration-300 md:rounded-full rounded-sm`}
        >
            <span className="flex-1 px-4">{label}</span> <span className="w-12 flex items-center justify-center bg-rose-500 group-hover:w-16 h-full transition-all duration-300 ease-in-out"><FiArrowRight /></span>
        </button>
    )
}