import ErrorPage from "../ui/errors/ErrorPage";
import HeadCustom from "../HeadCustom";

const PageNotFound = () => {
  return (
    <>
      <HeadCustom headtitle={"Page Introuvable"} />
      <ErrorPage />
    </>
    
  )
}

export default PageNotFound;