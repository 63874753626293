import { AnimatePresence } from "framer-motion";
import useFetch from "../../../../hooks/useFetch";
import ActusItem from "../atoms/ActusItem";

const ActusList = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/posts`;

    const { data, isLoading, error } = useFetch(apiUrl);

    if (error) return <span className="border border-slate-400 rounded-sm p-2 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 text-xs text-slate-400 text-center">Oups ! Une erreur s’est produite. Réessayez.</span>

    return (
        <ul className="xl:py-24 py-10 xl:gap-8 flex flex-col items-center justify-center w-full gap-4 pb-10">
            <AnimatePresence>
                {isLoading ? (
                    <span className="border border-slate-300 rounded-sm p-2 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 text-xs text-slate-500">
                        Chargement des actualités...
                    </span>
                ) : data?.post.length > 0 ? (
                    data?.post.slice(0, 3).map((item, index) => (
                        <ActusItem key={index} data={item} index={index} />
                    ))
                ) : (
                    <span className="border border-slate-300 rounded-sm p-2 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 text-xs text-slate-500">
                        Il n'y'a pas d'actualité dans notre site pour le moment
                    </span>
                )}
            </AnimatePresence>
        </ul>
    );
};

export default ActusList;
