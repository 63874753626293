import { useParams } from "react-router-dom";
import { UniqueCompaign } from "../components/organism/UniqueCompaign";
import useFetchCampaignBySlug from "../hooks/useFetchCompaignBySlug";
import PageNotFound from "../../../pages/PageNotFound";
import { AnimatePresence } from "framer-motion";
import { useContext, useEffect } from "react";
import { LoadingPage } from "../../../ui/LoadingPage";
import { LoadingContext } from "../../../context/loadingContext";

export const DisplayCompaign = () => {
    const { slug } = useParams();

    const { loading, startLoading } = useContext(LoadingContext);
    
    useEffect(() => {
        startLoading();
    }, [startLoading]);
    
    const { data, error, isLoading } = useFetchCampaignBySlug(slug)

    if (loading) return <LoadingPage isText="Campagnes en cours ..."/>;

    if (error) return <PageNotFound />

    return (
      
        <AnimatePresence>
            { !isLoading && 
                <UniqueCompaign data={data} display={true} />
            }
        </AnimatePresence>
        
        
    )
}