import {useEffect, useState} from 'react';
import {DataCompaign} from "../../../data/Data";

const useFetchAllProjects = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        handleFetchProjects()
    }, []);

    const handleFetchProjects = () => {

        setIsLoading(true);

        try {
            const res = DataCompaign;

            setData(res);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setError(error);
            setIsLoading(false);
        }
    }

    return { data, isLoading, error }
};

export default useFetchAllProjects;
