import { useContext, useEffect } from "react";
import { LoadingContext } from "../context/loadingContext";
import HeadCustom from "../HeadCustom";
import { Container } from "../ui/atoms/Container";
import Paragraph from "../ui/atoms/Paragraph";
import { DevisButton } from "../ui/atoms/DevisButton";
import { CheckText } from "../ui/organisms/CheckText";
import { motion } from "framer-motion";
import { Galerie } from "../ui/organisms/Galerie";
import { LoadingPage } from "../ui/LoadingPage";
import { Article } from "../ui/atoms/Article";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

export const Studio = () => {
    const { loading, startLoading } = useContext(LoadingContext);

    useEffect(() => {
        startLoading();
    }, [startLoading]);

    if (loading) return <LoadingPage isText="Lab en cours ..." />;
    
    return (
        <>
            <HeadCustom headtitle={"Mobali-Lab studio"} />

            <Article 
                className="lg:py-24 py-14"
                style={{
                    backgroundImage:"url(./uploads/bgwhite.webp)",
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat"
                }}     
            >
                <Container className="flex flex-col lg:gap-24">
                    <div className="flex flex-col gap-10 lg:pt-16 pt-8">
                        <motion.div
                            className={`flex lg:flex-row flex-col gap-10`}
                            variants={{
                                hidden: { opacity: 0 },
                                visible: { opacity: 1 },
                            }}
                            whileInView="visible"
                        >
                            <div className="lg:max-w-xl w-full flex flex-col gap-4">
                                <motion.h2
                                    className="2xl:text-7xl xl:text-4xl text-2xl font-black text-slate-900 pr-8 leading-relaxed z-20"
                                    initial={{opcity: 0, translateY:5}}
                                    whileInView={{opacity: 1, translateY:0}}
                                    
                                    transition={{type: "spring", stiffness: 100, damping: 10}}
                                >
                                    Un équipement premium à votre disposition
                                </motion.h2>

                                <Link
                                    to={`/lab/galeries`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-orange-400 xl:max-w-96 lg:max-w-52 flex items-center gap-2 cursor-pointer group hover:text-orange-200 2xl:text-2xl text-sm transition ease-in-out duration-75"
                                    >
                                    Voir la galerie
                                    <span className="group-hover:translate-x-4 transition ease-in-out duration-200 text-orange-400">
                                        <FiArrowRight />
                                    </span>
                                </Link>
                            </div>
                        
                            
                            <div className="grow lg:w-1/3 w-full flex flex-col divide-y divide-slate-300">
                                <div className="pb-8">
                                    <ul className="flex flex-col gap-4 pr-10">
                                        <CheckText content=" Studio avec fond vert, éclairage professionnel & caméras 4K" />
                                        <CheckText content="Studio audio isolé avec micros professionnels & mixage en direct" />
                                        <CheckText content="Espaces modulables pour shootings, interviews et événements" />
                                    </ul>
                                </div>
                                
                            </div>
                        
                        </motion.div>

                        <Galerie />   
                    </div>
                    
                    {/*  */}
                    <div className="lg:max-w-4xl mx-auto flex flex-col items-center gap-4">
                        <motion.p
                            className="2xl:text-7xl xl:text-4xl text-2xl font-black text-slate-900 text-center leading-relaxed z-20"
                            initial={{opcity: 0, translateY:5}}
                            whileInView={{opacity: 1, translateY:0}}
                            
                            transition={{type: "spring", stiffness: 100, damping: 10}}
                        >
                            Vous cherchez un espace professionnel pour donner vie à vos projets créatifs ?
                        </motion.p>
                        <Paragraph     
                            content="Profitez d’un studio modulable avec un équipement de pointe, idéal pour des productions professionnelles et immersives." 
                            customStyle="lg:max-w-2xl text-center mb-8"
                        />

                        <DevisButton label="Réservez votre créneau dès maintenant" />
                    </div>
                </Container>

            </Article>      
        </>
        
        
    )
}