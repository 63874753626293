import { useSelector } from "react-redux";
import { mydata } from "../../features/user/userSlice";
import { Avatar } from '../Avatar';

export const Profil = () => {
    const data = useSelector(mydata);
    
    return(
        <div className="flex flex-col items-center justify-center gap-2">
            <Avatar avatar={data.avatar} size={ size }/>
            <div className="flex flex-col gap-1 items-center justify-center">
                <p className="text-slate-200 text-md font-medium uppercase" style={{ marginBottom:"0px"}}>{data.firstname ? data.firstname : data.pseudo}</p>
                <span className="text-sm text-slate-900 bg-slate-100/70 w-fit px-2 rounded-sm">{data.role}</span>
            </div>
        </div>
    )
}

const size = {
    height: "70px",
    width: "70px"
}