import { Article } from "../ui/atoms/Article"
import { Container } from "../ui/atoms/Container"
import Heading from "../ui/atoms/Heading"
import Paragraph from "../ui/atoms/Paragraph"
import { ParagraphAnimated } from "../ui/atoms/Word"
import { LinkCustom } from "../ui/atoms/LinkCustom"
import { AboutSlv } from "../ui/organisms/AboutSlv"
import { FiChevronDown } from "react-icons/fi"
import { LoadingPage } from "../ui/LoadingPage"
import { LoadingContext } from "../context/loadingContext"
import { useContext, useEffect } from "react"
import HeadCustom from "../HeadCustom"

export const Apropos = () => {
    const { loading, startLoading } = useContext(LoadingContext);

    useEffect(() => {
        startLoading();
    }, [startLoading]);

    if (loading) return <LoadingPage />;
    
    return (
        <>
            <HeadCustom headtitle={"À propos de la Mobali-lab"} />

            <Article 
                className="lg:pt-52 pt-24"
                style={{
                    backgroundImage:"url(./uploads/bgwhite.webp)",
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat"
                }}    
            >
            <Container className="flex flex-col lg:gap-24 gap-10 items-center lg:min-h-[70vh] min-h-screen">

                <div className="lg:max-w-2xl w-full mx-auto flex flex-col">
                    <Heading 
                        typeHeading="h2" 
                        content="L’Agence d’Influence 360°" 
                        color="text-slate-900" 
                        size="lg:text-7xl text-4xl"
                        className="text-center lg:mb-8 mb-4"
                    />
                    <Paragraph 
                        content="Mobali-Lab est une agence de marketing d’influence spécialisée dans la gestion de campagnes publicitaires 360°. Nous accompagnons les marques à travers une stratégie omnicanale : réseaux sociaux, affichage publicitaire, événementiel et production audiovisuelle."   
                        customStyle="text-center"
                        size="Large"
                    />
                    <span className="order-first text-orange-400 text-md text-center font-medium">Mobali-Lab</span>
                </div>

                <div className="flex lg:flex-row flex-col lg:divide-x divide-slate-500 w-full">
                    <div className="lg:w-1/2 w-full lg:py-0 py-10 px-10 flex gap-4 flex-col justify-center items-center">
                        <div>
                        <Paragraph content="Vous êtes un influenceur ?" customStyle="font-bold text-center" size="lg:text-2xl" />
                        <Paragraph 
                            content="Rejoignez l'agence Mobali-Lab et collaborez avec des marques influentes. Développez votre réseau et monétisez votre audience." 
                            customStyle="text-center"
                        />
                        </div>
                        
                        <LinkCustom label="Catalogue Influenceurs" url="/catalogueinflue.pdf" />
                    </div>
                    <div className="lg:w-1/2 w-full lg:py-0 py-10 px-10 flex gap-4 flex-col justify-center items-center">
                        <div>
                        <Paragraph 
                            content="Vous êtes une marque ?" 
                            customStyle="font-bold text-center" 
                            size="lg:text-2xl" 
                        />
                        <Paragraph 
                            content="Découvrez comment Mobali-Lab peut booster votre visibilité en collaborant avec des influenceurs pertinents et engagés." 
                            customStyle="text-center"
                        />
                        </div>
                        
                        <LinkCustom label="Catalogue Mobali-Lab" url="/catalogue2025.pdf" />
                    </div>
                </div>
            </Container>

            <AboutSlv />

            <Container className="py-24 relative">
                <div className="min-h-screen relative  flex-col gap-24 flex items-center justify-center">
                    <ParagraphAnimated value="Bonjour et bienvenue sur notre site ! Je suis ravi que vous preniez le temps de découvrir notre agence. Je m'appelle Sylvain et je suis le responsable de Lab Mobali. Notre mission est d’accompagner les entreprises, les créateurs de contenu et bien d'autres dans l'élaboration de stratégies marketing, de communication et audiovisuelles, afin de vous offrir une visibilité optimale et d'atteindre vos publics cibles. 
                
                    Nous croyons fermement que chaque projet mérite une attention particulière. C'est pourquoi nous mettons un point d'honneur à collaborer étroitement avec vous pour garantir la réussite de vos initiatives. Depuis le lancement de notre aventure dans le marketing d’influence en 2020, avec une passion débordante, nous avons évolué pour devenir l'une des agences référencées en France. Aujourd'hui, nous sommes fiers de pouvoir nous déployer à l'international, avec une présence en Asie, en Afrique et en Amérique du Nord.

                    Notre agence se distingue par son authenticité et sa sincérité. Nous sommes là pour vous écouter, vous conseiller et vous aider à professionnaliser vos projets tout en vous offrant une gestion de projet efficace. Ensemble, faisons de vos ambitions une réalité ! Merci et à bientôt dans un monde remplit de bull." 
                    
                    />
                </div>
                <div className="absolute lg:top-0 -top-10 left-1/2 transform -translate-x-1/2">
                     <div className="flex flex-col">
                        <span className="text-md text-slate-700/20 block lg:mb-2">Scroll Down</span>
                        <span className="lg:h-40 h-20 w-[1px] bg-slate-400 block mx-auto"></span>
                        <span className="text-slate-400 block text-xl w-fit mx-auto"><FiChevronDown /></span>
                    </div>
                </div>
               
            </Container>
            
            </Article>
        </>
    
    )
}