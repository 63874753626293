export const DataCompaign = [
  {
    id: 1,
    title: "Baya.zm publicité",
    bigTitle: "@Baya.zm illumine la scène publicitaire avec Wero et BUZZMAN !",
    resume: "Nous sommes ravis de partager le succès de notre talent Baya.zm, suivi par plus de 40 000 personnes sur Instagram, qui a brillé dans une publicité pour l’enseigne Wero. Réalisée par l’agence renommée BUZZMAN, cette collaboration met en lumière son talent et son influence croissante sur les réseaux sociaux. Félicitations à Baya.zm pour cette étape majeure, et merci à BUZZMAN et Wero pour leur confiance !",
    mainImage:"baya",
    imagePreview:"baya",
    video:"baya",
    created:"25 Sept 2024",
    slug: "baya",
    category:["collabs"],
    url:"",
    status: true  
  },
  {
    id: 2,
    title: "Release party de Negrito",
    bigTitle: "Negrito Release Party : Une Soirée Pour Célébrer La Créativité Musicale",
    resume: "Chez Mobali-Lab, nous croyons en la puissance de la musique pour rassembler et inspirer. C’est pourquoi nous avons organisé une release party exceptionnelle pour marquer la sortie du nouvel album de l’artiste Negrito. Cette soirée mémorable a permis aux fans de plonger dans l’univers créatif de l’artiste, tout en célébrant son travail dans une ambiance festive et immersive. ",
    mainImage:"releaseparty",
    imagePreview:"realeaseparty",
    video:"releasepartynegrito",
    created:"01 Janv 2024",
    slug: "releasepartynegrito",
    category:["events"],
    url:"",
    status: true  
  },
  {
    id: 3,
    title: "Aide Mondial : Concert caritatif",
    bigTitle:"Aide Mondiale x Mobali : Concerts Caritatifs pour un Monde Meilleur",
    resume:"Mobali-Lab a collaboré avec Aide Mondiale pour organiser deux concerts caritatifs uniques dans des lieux emblématiques comme La Cigale et Le Bataclan. Ces événements avaient pour objectif de collecter des fonds en soutien à des initiatives humanitaires telles que l’accès à l’eau potable, l’éducation et les soins de santé. Plus qu’un simple événement, ce partenariat a permis de sensibiliser un large public aux enjeux mondiaux actuels tout en offrant des moments artistiques inoubliables.",
    display: ["photo", "aidemondiale_project"],
    mainImage:"aidemondial",
    imagePreview:"aidemondial-prev",
    video:"aidemodiale",
    created:"16 Sept 2024",
    slug: "aide-mondiale-x-mobali",
    category:["events"],
    url:"",
    status: true  
  },
  {
    id: 4,
    title: "@Baya.zm x Fenty Beauty",
    bigTitle:"@Baya.zm brille aux côtés de Fenty Beauty grâce à Nicework Paris !",
    resume:"Nous sommes ravis de vous annoncer que notre talent @Baya.zm a eu l'opportunité de collaborer avec Fenty Beauty, grâce à notre partenaire Nicework Paris. 🎉💄 Une belle campagne qui met en lumière son talent et son univers, au service d’une marque emblématique. Un grand merci à nos partenaires pour leur confiance ! 🚀 Chez @Mobali, nous accompagnons nos talents vers des collaborations inspirantes et impactantes. Hâte de partager avec vous nos prochains projets !",
    display: ["photo", "bayaxfenty"],
    mainImage:"bayaxfenty",
    imagePreview:"bayaxfenty",
    video:"bayaxfenty",
    created:"16 Sept 2024",
    slug: "baya-x-fenty",
    category:["collabs"],
    url:"",
    status: true  
  },
  {
    id: 7,
    title: "Triangle des Bermudes",
    bigTitle: "Comment TikTok a Propulsé ‘Lunettes’ de Triangle des Bermudes en Tendance",
    resume: "Pour promouvoir 'Lunettes', Triangle des Bermudes a misé sur une campagne d’influence sur TikTok, invitant les créateurs à intégrer le son dans leurs contenus. Le résultat ? Un véritable succès : la musique est rapidement devenue tendance sur l’application et a rythmé tout l’été, confirmant une fois de plus l’impact de TikTok dans la promotion musicale.",
    display: ["photo", "trianglebermudes"],
    mainImage: "trianglebermudes",
    imagePreview: "trianglebermudes",
    
    created: "Juin 2024",
    slug: "triangle-des-bermudes",
    view: {
      numb: "120k",
      modified: "Juin 2024"
    },
    stream: {
      numb: "+5M",
      modified: "Juin 2024"
    },
    tiktokcontentcreator: {
      numb: "+1.5K (sur le son)",
      modified: "Juin 2024"
    },
    category: ["music"],
    url:"",
    status: false  
  },
  {
    id: 14,
    title: "@Souvenirs: Stony Feat Kalipsxau",
    bigTitle: "Souvenirs – Quand la musique devient un moment partagé",
    resume: "Pour faire vibrer Souvenirs de Stony feat. Kalipsxau, Mobali a lancé une campagne d’influence immersive sur TikTok. En invitant les créateurs à intégrer le son dans leurs contenus, la campagne a rapidement transformé cette mélodie en un incontournable. Résultat ? Une vague de vidéos authentiques et nostalgiques qui ont fait de Souvenirs la bande-son idéale des moments marquants, confirmant une fois de plus l’impact de TikTok dans la promotion musicale.",
    display: ["photo", "stony"],
    mainImage: "stony",
    imagePreview: "stony",
    
    created: "Juin 2024",
    slug: "stony",
    view: {
      numb: "700K",
      modified: "Juin 2024"
    },
    category: ["music"],
    url:"https://www.youtube.com/watch?v=73wlIlWGV1w",
    status: true  
  },
  {
    id: 15,
    title: "@Elle m'a dit: Waiv",
    bigTitle: "Elle m’a dit : Waiv – La tendance qui fait vibrer l’influence",
    resume: "Pour propulser Elle m’a dit : Waiv, Mobali a misé sur une campagne d’influence percutante en mobilisant les créateurs de contenu sur TikTok. L’objectif ? Transformer ce son viral en une véritable tendance à travers des vidéos créatives et engageantes. Le résultat ne s’est pas fait attendre : adopté massivement, Waiv a rythmé les feeds et confirmé, une fois de plus, la puissance de TikTok dans la diffusion des trends culturelles.",
    display: ["photo", "ellemadit"],
    mainImage: "ellemadit",
    imagePreview: "ellemadit",
    
    created: "Juin 2024",
    slug: "ellemadit",
    stream: {
      numb: "+206k",
      modified: "Juin 2024"
    },
    category: ["music"],
    url:"https://open.spotify.com/intl-fr/track/2MEVxqgn8RgrTN0Yxrx0HT",
    status: true  
  },
  {
    id: 8,
    title: "WePlay",
    bigTitle: "WePlay sur les Réseaux Sociaux : Une Stratégie Gagnante pour Attirer les Joueurs",
    resume: "Pour promouvoir l’application de jeu WePlay, une vaste campagne a été déployée sur TikTok, Snapchat et Instagram, mobilisant de nombreux influenceurs partenaires de Mobali. WePlay est une application de jeu social qui propose une large variété de mini-jeux en ligne, permettant aux utilisateurs de jouer, discuter et interagir en temps réel. Le succès a été au rendez-vous : la campagne a généré un fort taux d’interaction et un nombre significatif de téléchargements de WePlay. En combinant authenticité, viralité et influence, cette activation a prouvé l’efficacité d’une stratégie digitale bien pensée pour toucher un large public et inciter à l’action.",
    display: ["photo", "weplay"],
    mainImage: "weplay",
    imagePreview: "weplay",
    created: "Dec 2024",
    slug: "weplay",
    like: {
      numb: "+70K",
      modified: "Dec 2024"
    },
    view: {
      numb: "+800K",
      modified: "Dec 2024"
    },
    category: ["gaming"],
    url:"",
    status: true  
  },
  {
    id: 9,
    title: "Concert de Burna Boy",
    bigTitle: "Snapchat en Feu pour le Concert de Burna Boy au Stade de France",
    resume: "Pour promouvoir le concert événement de Burna Boy au Stade de France le 18 avril 2025, une campagne d’influence sur Snapchat a été déployée, mettant en avant l’excitation et l’énergie de l’événement à travers des contenus exclusifs. Grâce à l’engagement des créateurs de contenu, la stratégie digitale a généré un engouement massif, contribuant à la vente de presque toutes les places et confirmant l’énorme popularité de l’artiste auprès du public français.",
    display: ["photo", "stadedefranceburna"],
    mainImage: "stadedefranceburna",
    imagePreview: "stadedefranceburna",
    created: "Sept 2024",
    slug: "concert-de-burna-boy",
    view: {
      numb: "+300k",
      modified: "Sept 2024"
    },
    category: ["music"],
    url:"",
    status: true  
  },
  {
    id: 9,
    title: "Campagne d'Influence AliExpress",
    bigTitle: "AliExpress mise sur l’Influence pour marquer les Jeux Olympiques de Paris",
    resume: "Pendant les Jeux Olympiques de Paris 2024, AliExpress a lancé une campagne d’influence originale sur TikTok, invitant des créateurs de contenu à défendre un sport de leur choix et à expliquer pourquoi il mériterait une place aux JO. Grâce à leur créativité et leur engagement, les influenceurs ont généré un fort engouement autour de disciplines parfois méconnues, captivant les communautés et suscitant le débat. Une activation digitale réussie qui a su mêler sport, divertissement et viralité pour marquer les esprits durant l’événement mondial.",
    display: ["photo", "aliexpress"],
    mainImage: "aliexpress",
    video:"aliexpress",
    imagePreview: "aliexpress",
    created: "25 Jan 2025",
    slug: "aliexpress",
    category: [ "events"],
    url:"",
    status: true  
  },
  {
    id: 9,
    title: "@J'ai Payé : Negrito & Franglish",
    bigTitle: "Negrito & Franglish : Une Campagne TikTok réussie pour 'J’ai Payé'",
    resume: "Pour promouvoir 'J’ai Payé', le titre de Negrito en collaboration avec Franglish, une campagne d’influence sur TikTok a été mise en place, incitant les créateurs à s’approprier le son à travers des trends et challenges viraux. Le succès a été immédiat : la musique a généré un énorme engouement sur la plateforme et s’est hissée dans le TOP 50 en France, confirmant l’impact du marketing digital sur l’industrie musicale.",
    display: ["photo", "negrito"],
    mainImage: "negrito",
    imagePreview: "negrito",
    created: "1 Jan 2024",
    slug: "negrito",
    stream: {
      numb: "+5M",
      modified: "1 Jan 2024"
    },
    tiktokcontentcreator: {
      numb: "+2K",
      modified: "1 Jan 2024"
    },
    category: ["music"],
    url:"",
    status: true  
  },
  {
    id: 10,
    title: "Tu Ris Tu Perds",
    bigTitle: "Quand les influenceurs Mobali font de « Tu Ris Tu Perds » un jeu viral",
    resume: "Pour promouvoir le jeu de société 'Tu Ris Tu Perds', Dujardin a lancé une campagne d’influence sur TikTok et Instagram, portée par Isma, Capelovie, Mikacho et Neva. Ensemble, ils ont réalisé une vidéo commune pour présenter le concept du jeu à leurs abonnés, mêlant humour et challenge. Leur contenu a généré un fort engagement et contribué à faire de cette activation un véritable succès viral.",
    display: ["photo", "turistuperd"],
    mainImage: "turistuperd",
    video:"turistuperd",
    imagePreview: "turistuperd",
    created: "Jan 2024",
    slug: "turistuperds", 
    view: {
      numb: "+2,4M",
      modified: "Jan 2024"
    },
    category: ["gaming"],
    url:"",
    status: true
  },
  {
    id: 11,
    title: "@Isma9un x PUMA",
    bigTitle: "PUMA s'associe avec @Isma9un pour une Collection Urban Style",
    resume: "Pour promouvoir l’une de ses nouvelles paires de chaussures, PUMA a lancé une campagne d’influence sur Instagram et TikTok, mêlant style, performance et culture urbaine. Parmi les créateurs sélectionnés, Isma9un, talent Mobali, a apporté sa touche unique à l’activation, captant l’attention de sa communauté à travers un contenu percutant et authentique. Une collaboration qui a renforcé l’impact de la campagne et confirmé l’influence de PUMA dans l’univers streetwear.",
    display: ["photo", "ismafeatpuma"],
    mainImage: "ismafeatpuma",
    imagePreview: "ismafeatpuma",
    created: "Oct 2024",
    slug: "isma9un",
    category: ["collabs"],
    url:"",
    status: true  
  },
  {
    id: 12,
    title: "@Camerstyle x Lipault",
    bigTitle: "@Camerstyle s'associe à Lipault pour une Collection Capsule Unique",
    resume: "Nous sommes fiers d’annoncer que notre talent @Camerstyle a récemment collaboré avec *Lipault* pour une campagne unique, mêlant style et élégance. 🎒✨ Un projet qui reflète parfaitement son univers et met en avant l’ADN de la marque. Merci à *Lipault* pour leur confiance et bravo à *@Camerstyle* pour cette belle réalisation ! Chez *@Mobali*, nous connectons les talents aux marques pour des collaborations authentiques et impactantes. Hâte de vous dévoiler nos prochains projets ! 🚀",
    display: ["photo", "lipaulxcamerstyle"],
    mainImage: "lipaulxcamerstyle",
    imagePreview: "lipaulxcamerstyle",
    video: "lipaulxcamerstyle",
    created: "Jan 2025",
    slug: "lipaul-x-camerstyle",
    category: ["collabs"],
    url:"",
    status: true  
  },
  {
    id: 13,
    title: "@Ism9un x Armani",
    bigTitle: "✨Notre talent @isma9un x Armani 🔥✨ ",
    resume: "Nous sommes ravis d’annoncer que @isma9un s’est connecté avec Armani pour une collaboration exceptionnelle ! 🌟 Son univers raffiné et son authenticité font d’elle une parfaite ambassadrice pour sublimer l’élégance et le prestige de la marque.Restez connectés pour découvrir cette belle aventure ✨💫",
    display: ["photo", "armaniisma"],
    mainImage: "armaniisma",
    imagePreview: "armaniisma",
    created: "Jan 2025",
    slug: "armani-x-isma",
    category: ["collabs"],
    url:"",
    status: true  
  },
  {
    id: 16,
    title: "@Isma9un x Kaporal",
    bigTitle: "Kaporal s'associe avec @Isma9un pour une campagne 100% streetwear",
    resume: "Pour mettre en avant son dernier drop, Kaporal a lancé une campagne d’influence sur Instagram et TikTok en s’associant à Isma9un, talent de Mobali-Lab. À travers des contenus immersifs mêlant mode, lifestyle et culture urbaine, Isma9un a su captiver sa communauté en incarnant l’ADN streetwear de la marque. Une collaboration authentique qui a boosté l’engagement et renforcé la présence de Kaporal auprès d’une audience jeune et connectée.",
    display: ["photo", "kaporalxisma"],
    mainImage: "kaporalxisma",
    imagePreview: "kaporalxisma",
    created: "Oct 2024",
    slug: "kaporalxisma",
    category: ["collabs"],
    url: "",
    status: true
}
]
