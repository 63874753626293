export const customDate = (dt) => {
    const options = {  month: 'long', day: 'numeric', year: 'numeric' };
    const date = new Date(dt).toLocaleDateString('fr-FR', options);
  
    return date;
}

export const customDateCompleted = (dt) => {
    const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };
    const date = new Date(dt).toLocaleString('fr-FR', options);

    return date;
};


export const currentDate = () => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date().toLocaleDateString('fr-FR', options);
  
    return date;
}