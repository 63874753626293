import Button from "../atoms/Button";
import { dataSocial } from "../../utils/myData";

const Social = ({ bgColor, first = true, size, email, col }) => {
    const handleClick = (id, url) => {
        if (id === 1) return window.open(url, "_blank");
        if (id === 2) return window.open(url, "_blank");

        if (id === 3) {
            const subject = encodeURIComponent("Demande d'information sur nos services d'influence");
            const body = encodeURIComponent("Bonjour MobaliCorp,\n\nJe souhaite en savoir plus sur vos services d'influence...");
            const email = "mobalicorp@mobalicorp.com";

            const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
            return window.open(mailtoLink);
        }
    };

    return (
        <ul className={`flex ${col && "xl:flex-col"} gap-4 ${first && "order-first"}`}>
            {dataSocial.map((item) => (
                <li
                    key={item.id}
                    className={(item.id === 3 && !email) ? "none" : undefined}
                >
                    <Button
                        title={item.title}
                        icon={item.icon}
                        rounded="full"
                        border
                        variant="outlined"
                        color={bgColor ? "primary" : "secondary"}
                        size={size}
                        handler={() => handleClick(item.id, item.url)}
                    />
                </li>
            ))}
        </ul>
    );
};

export default Social;