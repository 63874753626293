import React from 'react';
import { createRoot } from "react-dom/client";
import { AuthProvider } from './context/AuthContext';

// Redux setting
import store from './app/store.js'
import { Provider } from 'react-redux'

import App from './App';
import LoadingProvider from "./context/loadingContext";
import {DrawerProvider} from "./context/DrawerContext";
import {Toaster} from "sonner";
import {ReactLenis} from "lenis/react";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store} >
    <Toaster richColors position="top-center" />
    <AuthProvider>
        <LoadingProvider>
            <DrawerProvider>
                <ReactLenis root
                  options={{
                    lerp: 0.05, // Reduced from 0.1 for smoother interpolation
                    duration: 1.5, // Added duration for slower scrolling
                    smoothWheel: true, // Ensures smooth mouse wheel scrolling
                    wheelMultiplier: 0.8, // Reduced wheel sensitivity
                    touchMultiplier: 1.5, // Adjusted touch sensitivity
                    smooth: true // Ensures smooth scrolling is enabled
                  }}
                >
                    <App />
                </ReactLenis>
            </DrawerProvider>
        </LoadingProvider>
    </AuthProvider> 
  </Provider>
    
);