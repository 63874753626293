import { Container } from "../../../../ui/atoms/Container";
import Heading from "../../../../ui/atoms/Heading";
import CustomSwipper from "../../../../ui/slider/SwiperCustom";
import { DataCompaign } from "../../../../data/Data";
import { useEffect, useState } from "react";

export const CategoryCompaign = ({ latestCompaignId, category, categoryTitle }) => {
    const [dataByCategory, setDataByCategory] = useState([]);
    
    useEffect(() => {
        let data;
        if (category) {
            // Filter by status, category, and exclude latestCompaignId
            const activeItems = DataCompaign?.filter(item => item.status === true);
            data = activeItems
                ?.filter(item => item.category.includes(category))
                ?.filter(item => item.id !== latestCompaignId) // Exclude the latest campaign
                .reverse();
        }

        setDataByCategory(data);
        
    }, [category, latestCompaignId]); // Add latestCompaignId to dependencies
    
    return (
        <>
            { dataByCategory?.length > 0 && 
            <Container className="h-auto">
                <Heading size="lg:text-lg text-sm" typeHeading="h3" content={categoryTitle} className="mb-8 text-left" />
                <ul className="flex items-center gap-4">
                    <CustomSwipper data={dataByCategory} />
                </ul>
            </Container>
            }
        </>
    )
}