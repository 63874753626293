import { motion} from "framer-motion";

export const ParallaxService = ({ className, title, content, icon }) => {

    return (
        <motion.div
            className={`${className} bg-gradient-to-br from-orange-600 to-yellow-600 text-white p-6 xl:rounded-lg xl:w-full h-[550px] shadow-xl cursor-default relative overflow-hidden`}
        >
            {icon && <span className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" >{icon}</span>}
                <div className="relative z-10">
            { icon && 
                <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-orange-600 grid place-items-center mx-auto">
                    {icon}
                </div> 
            }
                <h3 className="xl:text-3xl font-bold text-center mb-2">
                {title}
                </h3>
                <p className="text-center xl:text-base lg:text-sm text-lg mb-6">
                    {content}
                </p>
            </div>
        </motion.div>
    );
  };