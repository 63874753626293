import { ScrollTriggeredVideo } from "./organisms/ScrollTriggeredVideo";
import { HorizontalScrollCarousel } from "../organisms/HorizontalScrollCarousel";
import useFetchAllProjects from "../../features/projects/hooks/useFetchAllProjects";
import { infosCompaign } from "../../utils/myData";
import { AboutHero } from "./organisms/AboutHero";
import Customer from "../Customer";
const About = () => {
    const { data } = useFetchAllProjects();

    const dataLimite = data?.slice(0, 6);

    return (
        <article 
            className={`h-auto w-full bg-slate-50 relative flex flex-col items-center`}    
        >
            
            <AboutHero />
            
            <Customer />

            <ScrollTriggeredVideo />
            
            <HorizontalScrollCarousel infos={infosCompaign} type="campaign" data={dataLimite} />

        </article>
    );
};

export default About;
