import { motion, useAnimationFrame, useMotionValue, useScroll, useSpring, useTransform, useVelocity, wrap } from "framer-motion";
import { useRef } from "react";

export const TextVelocity = ({ children, baseVelocity = 100 }) => {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="overflow-hidden tracking-wider m-0  whitespace-nowrap flex flex-nowrap items-center">
        <motion.div style={{ x }} className=" md:text-3xl tracking-wider text-xl font-black text-slate-50 flex whitespace-nowrap flex-nowrap">
        <span className="block mr-10">{children} </span>
        <span className="block mr-10">{children} </span>
        <span className="block mr-10">{children} </span>
        <span className="block mr-10">{children} </span>
        <span className="block mr-10">{children} </span>
      </motion.div>
    </div>
      
  );
}

export const ParallaxText = () => {
  return (
    <section className="relative bg-gradient-to-t from-40% from-orange-400 to-rose-400 w-full md:h-16 h- py-4 flex items-center justify-center">
      
      <TextVelocity baseVelocity={2}> Mobali-Lab un monde remplit de bull</TextVelocity>

      <div className="absolute top-0 left-0 h-full lg:w-52 w-24 bg-gradient-to-r from-20% from-orange-400 to-transparent "></div>
      <div className="absolute top-0 right-0 h-full lg:w-52 w-24 bg-gradient-to-l from-20% from-orange-400 to-transparent "></div>
    </section>
  );
}