import { SliderLogo } from "./slider/SliderLogo";
const Customer = () => {
  return (
    <div className="overflow-hidden w-full 2xl:h-72 h-40 drop-shadow-sm bg-slate-50 z-30 flex flex-col gap-10 items-center justify-center">
      <p className="flex items-center justify-center gap-10 relative w-full"> 
        <span className="h-[1px] bg-slate-400 w-1/4 grow block"></span>
        <span className="text-slate-700 lg:text-sm text-xs text-center min-w-20 ">Ils nous ont fait confiance</span>
        <span className="h-[1px] bg-slate-400 w-1/4 grow block"></span>
      </p>

      <SliderLogo />
    </div>
  );
};

export default Customer;
