import { useEffect, useRef, useState } from 'react';

export const LazyVideo = ({ videoSrc, poster }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );
    if (videoRef.current) observer.observe(videoRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div ref={videoRef} className="w-full lg:h-screen h-[550px]">
      {isVisible && (
        <video
            className="w-full lg:h-screen h-[550px] object-cover"
            playsInline
            controlsList={"nofullscreen"}
            controls
            muted
            poster={poster}
        >
          <source src={`${videoSrc}.webm`} type="video/webm" />
          <source src={`${videoSrc}.mp4`} type="video/mp4" />
        </video>
      )}
    </div>
  );
};