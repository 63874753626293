import {FiGitPullRequest, FiInstagram, FiSend, FiTrendingUp, FiZap} from "react-icons/fi";
import {FaTiktok} from "react-icons/fa";
import { MdOutlineCampaign } from "react-icons/md";

import Snipes from "../assets/images/snipes.webp";
import Aliexpress from "../assets/images/aliexpress_logo.svg.webp";
import Sherwoodparc from "../assets/images/sherwoodparc.webp";
import Soldejaneiro from "../assets/images/soldejaneiro.webp";
import Dujardin from "../assets/images/dujardin.webp";
import Cider from "../assets/images/cider-logo.webp";
import Lipault from "../assets/images/lipault.webp";

import Puma from "../assets/images/puma-logo.webp";
import Universalmusic from "../assets/images/universalmusic.webp";

export const dataServices = [
  {
    id: 1,
    title: "Stratégie d'influence",
    subtitle: "Des campagnes qui résonnent avec authenticité.",
    content:
      "Dans un monde où les consommateurs se tournent de plus en plus vers des recommandations authentiques, le marketing d’influence est votre allié pour construire une image forte et générer des ventes. En collaborant avec des influenceurs pertinents, nous amplifierons la voix de votre marque, atteindrons vos cibles, et ferons de vos produits une référence auprès de leur audience engagée.",
    image: "influence",
    icon: <MdOutlineCampaign />,
    start:200,
    end:200,
    className:"w-1/3"
  },
  {
    id: 2,
    title: "Community management",
    subtitle: "Animez et engagez votre audience au quotidien.",
    content:
      "Un Community Manager n’est pas seulement là pour publier des contenus, mais pour créer un lien durable entre votre marque et votre audience. Grâce à une stratégie ciblée, des interactions régulières et une analyse rigoureuse, nous ferons de vos réseaux sociaux un levier puissant pour votre visibilité, votre notoriété et votre chiffre d’affaires.",
    image: "management",
    icon: <FiZap />,
    start:200,
    end:-250,
    className:"w-1/3 mx-[500px]"
  },
  {
    id: 3,
    title: "Événement",
    subtitle: "Des expériences mémorables pour marquer les esprits.",
    content:
      "Imaginez un événement où chaque image, chaque son, et chaque effet visuel transmettent l’essence de votre message. Grâce à une stratégie audiovisuelle maîtrisée, nous transformons vos idées en une expérience inoubliable pour votre audience. Que vous souhaitiez captiver, inspirer ou laisser une trace durable, notre expertise en audiovisuel est la clé pour un événement réussi et mémorable.",
    image: "events",
    icon: <FiTrendingUp />,
    start:-200,
    end:200,
    className:"w-1/3 ml-auto"
  },
  {
    id: 4,
    title: "Mobali 360",
    subtitle: "Une approche complète pour un impact maximal.",
    content:
      "Avec une stratégie de marketing 360°, nous allons garantir à votre marque une présence forte et cohérente sur tous les canaux. Cela permettra non seulement d'attirer plus de clients, mais aussi de construire une relation durable avec eux. En analysant vos besoins spécifiques, nous créerons une stratégie personnalisée et mesurable pour atteindre vos objectifs.",
    image: "trois",
    icon: <FiGitPullRequest />,
    start:0,
    end:-500,
    className:"w-1/3 ml-24"
  },
];

export const dataSocial = [
  {
    id:1,
    title:"Instagram",
    icon:<FiInstagram />,
    url: "https://www.instagram.com/mobalicorps/",
  },
  {
    id:2,
    title:"TikTok",
    icon:<FaTiktok />,
    url: "https://www.tiktok.com/@mobaliprod",
  },
  {
    id:3,
    title:"Email",
    icon:<FiSend />
  }
]

export const dataStudioService = [
  {
    id:1,
    title:"La poésie visuelle",
    subTitle:"Studio de photographie",
    image:"studio"
  },
  {
    id:2,
    title:" L'Art en Mouvement",
    subTitle:"Studio de vidéo",
    image:"studio3"
  },
  {
    id:3,
    title:"La Mélodie Créative",
    subTitle:"Studio d'enregistrement",
    image:"studio2"
  }
]

// all Info on sections
export const infoAboutImpact = [
  {
    id: 1,
    text: "+200 campagnes réussies chaque année : Boostez votre visibilité et votre impact.",
    animation: { start: 0, end: 0 },
  },
  {
    id: 2,
    text: "+30 campagnes internationales : Touchez des audiences mondiales avec des stratégies ciblées.",
    animation: { start: 100, end: 0 },
  },
  {
    id: 3,
    text: "+20 talents exclusifs : Des collaborations authentiques et sur-mesure.",
    animation: { start: 200, end: 0 },
  },
  {
    id: 4,
    text: "+300 talents freelance : Une expertise adaptée à vos besoins spécifiques.",
    animation: { start: 300, end: 0 },
  },
  {
    id: 5,
    text: "+7 événements marquants : Transformez votre image avec des expériences mémorables.",
    animation: { start: 400, end: 0 },
  },
];

export const infoStudio = {
  title:"Nos Studios Mobali-Lab: Laissez Libre Cours à Votre Créativité",
  subtitle:"Un espace moderne, polyvalent et entièrement équipé pour réaliser tous vos projets créatifs.",
  content: "Découvrez un studio pensé pour donner vie à toutes vos ambitions : shootings photo, tournages vidéo, podcasts, ou encore événements privés. Profitez d’un espace professionnel et inspirant, équipé pour répondre à toutes vos attentes et créer des expériences uniques.",
  listText:[
    {
      id: 1,
      text: "Séances photos, Tournages vidéo, Enregistrements de podcasts.",
      animation: { start: 0, end: 0 },
    },
    {
      id: 2,
      text: "Shootings, Interviews, Réunions créatives.",
      animation: { start: 100, end: 0 },
    },
    {
      id: 3,
      text: "Un environnement professionnel et inspirant pour réaliser vos projets.",
      animation: { start: 200, end: 0 },
    },
    {
      id: 4,
      text: "Privatiser le studio pour des événements sur mesure ou lancements de produits.",
      animation: { start: 300, end: 0 },
    },
  ]
};

export const infoServices = {
  subtitle:"Des Solutions Complètes pour Booster Votre Présence et Atteindre Vos Objectifs",
  title:"Le Lab Mobali : Votre Espace Créatif pour Propulser Vos Projets Digitaux", 
  content: "Nous offrons des services complets, de la stratégie d’influence au community management, pour renforcer votre présence et atteindre vos objectifs.",
  listText:[
    {
      id: 1,
      text: "Stratégie d’Influence Personnalisée pour Chaque Objectif",
      animation: { start: 0, end: 0 },
    },
    {
      id: 2,
      text: "Community Management : Engagez, Fidélisez et Développez Votre Communauté",
      animation: { start: 100, end: 0 },
    },
    {
      id: 3,
      text: "Organisation d’Événements : Faites de Vos Projets une Réalité Mémorable",
      animation: { start: 200, end: 0 },
    },
    {
      id: 4,
      text: "Mobali 360 : Une Solution Globale pour Votre Réussite Digitale",
      animation: { start: 300, end: 0 },
    },
  ]
};

export const infosCompaign = {
  subtitle:"Découvrez comment nous transformons vos idées en vagues d’engagement",
  title:"Émotion × Data = Résultats Durables", 
  content: "Avec notre réseau de talents exclusifs, sélectionnés pour leur pertinence et leur authenticité, nous créons des connexions uniques entre votre marque et votre audience.",
};
 
//Footer
export const footerData = [
  {
    title:"À propos de nous",
    id:1,
    lists: [
      {
        id:11,
        linkName:"Nos campagnes",
        path:"campagnes",
        url:false
      },
      {
        id:12,
        linkName:"Nos Studios",
        path:"lab",
        url:false
      }
    ]
  },
  {
    title:"Media(s)",
    id:2,
    lists:[
      {
        id:21,
        linkName:"Mentions Légales",
        path:"mentionslegales",
        url:false
      }
    ]
  },
  {
     title:"Ressources",
     id:3,
     lists:[
      {
        id:31,
        linkName:"Actualités",
        path:"actus",
        url:false
      },
      {
        id:32,
        linkName:"Créer un devis",
        path:"creerundevis",
        url:false
      },
      {
        id:33,
        linkName:"Consulter notre catalogue",
        path:"catalog",
        url:true
      }
    ]
  },
  {
    title:"Social",
    id:4,
    lists:[
     {
       id:41,
       linkName:"Instagram",
       path:"https://www.instagram.com/mobalicorps/",
       url:true
     },
     {
       id:42,
       linkName:"Tiktok",
       path:"https://www.tiktok.com/@mobaliprod",
       url:true
     }
   ]
 }
]

// Compaign
export const compaign = ["bermunde","artiste","aliexpress2","negritoxfranglish","puma2","burnaboy1","ljg","weplay"];

export const listNavAbout = [
  {
    id: 1,
    title: "Notre histoire",
    content: "",
  },
  {
    id: 2,
    title: "Nos valeurs",
    content: "",
  },
  {
    id: 3,
    title: "Notre impact",
    content: "",
  },
  {
    id: 4,
    title: "Collaborations",
    content: "",
  },
];

//Project section
export const infoProject = {
  subtitle:"Une Sélection Qui Nous Inspire et Nous Ressemble",
  title:"Un Voyage à Travers Nos Projets : Chaque Réalisation Est Une Histoire de Succès",
  content: "Mobali-Lab réalise des projets innovants qui connectent les marques à leur audience. De la création de contenu à la stratégie de visibilité, chaque projet vise à renforcer l'impact et l'engagement des marques dans le monde digital.",
  listText:[
    {
      id: 1,
      text: "Ensemble, Construisons Des Projets Qui Vous Ressemblent et Créent de L’Impact",
      animation: { start: 0, end: 0 },
    },
    {
      id: 2,
      text: "Des Projets Conçus Avec Vous Pour Répondre à Vos Besoins et Réaliser Vos Rêves",
      animation: { start: 100, end: 0 },
    },
    {
      id: 3,
      text: "Une Approche Personnalisée Pour Des Projets Qui Racontent Votre Histoire Avec Sincérité",
      animation: { start: 200, end: 0 },
    },
    {
      id: 4,
      text: "Chaque Projet Naît d’une Collaboration Humaine et D’un Partage de Vos Objectifs",
      animation: { start: 300, end: 0 },
    },
  ]
}

//Studio section 
export const allPhotosUrl = [
  {
    id:10,
    mainImage:"_1151251",
    alt:""
  },
  {
    id:11,
    mainImage:"_1151254",
    alt:""
  },
  {
    id:12,
    mainImage:"_1151261",
    alt:""
  },
  {
    id:13,
    mainImage:"_1151271",
    alt:""
  },
  {
    id:14,
    mainImage:"_1152309",
    alt:""
  },
  {
    id:15,
    mainImage:"_1151267",
    alt:""
  },
  {
      id:1,
      mainImage:"studiomusic",
      alt:"Studio d'enregistrement"
  },
  {
      id:2,
      mainImage:"mobalilab",
      alt:"logo mobali et lab27"
  },
  {
      id:3,
      mainImage:"openspace",
      alt:"Open Space"
  },
  {
    id:4,
    mainImage:"open-space",
    alt:"Salon de talk show"
  },
  {
    id:5,
    mainImage:"studio-open",
    alt:"Studio de montage et photo"
  },
  {
    id:6,
    mainImage:"reu-room",
    alt:"Salle de reunion en grand"
  },
  {
    id:7,
    mainImage:"space",
    alt:"Escalier du studio"
  },
  {
      id:8,
      mainImage:"reu",
      alt:"Salle de reunion"
  },
  {
      id:9,
      mainImage:"streaming",
      alt:"Streaming setup"
  },

];

// Main menu
export const mainMenu = [
  {
    id: 1,
    title: "À propos",
    redirect_to: "apropos",
  },
  {
    id: 2,
    title: "Lab",
    redirect_to: "lab",
  },
  {
    id: 3,
    title: "Campagnes",
    redirect_to: "campagnes",
  },
  {
    id: 4,
    title: "Actus",
    redirect_to: "actus",
  }
]

// slider logo
export const silderLogo = [
  Puma,
  Snipes,
  Aliexpress,
  Universalmusic,
  Sherwoodparc,
  Soldejaneiro,
  Cider,
  Dujardin,
  Lipault
];

//Campaign
export const campaign = ["aliexpressjocampagne","pumacampagne", "jaipayenegritofranglishcampagne", "burnaboycampagne", "weplaylunchappcampagne"];