import { Link } from "react-router-dom"

export const List = ( { data }) => {

    const handleClick = (path, url) => {
        if (!url) return;

        if (path === "catalog") {
            window.open("/catalogue2025.pdf", "_blank");
        } else {
            window.open(`${path}`, "blank_");
        }
    }
    
    return (
        <ul className="flex flex-col gap-4 pl-2">
            {data.map( item => 
                <li key={item.id} >
                    <Link onClick={ () => handleClick(item.path, item.url)} to={`${ item.url ? "#" : `/${item.path}`}`} className="text-slate-200 2xl:text-xl text-sm hover:text-orange-200 transition duration-75 easeIn">
                        { item.linkName }
                    </Link>
                </li>
            )}
        </ul>
    )
}