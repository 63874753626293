import {useCallback, useRef, useState} from "react";
import Heading from "../../../../ui/atoms/Heading";
import { useForm, Controller } from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {FiArrowLeft, FiArrowRight, FiCheck} from "react-icons/fi";
import {toast} from "sonner";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { fr } from 'date-fns/locale/fr';
import {MdOutlineAccessTime, MdOutlineCalendarToday} from "react-icons/md";
import Button from "../atoms/Button";
import {format} from "date-fns/format";
import Paragraph from "../../../../ui/atoms/Paragraph";
import {motion} from "framer-motion";

registerLocale("fr", fr);

const Stepper = () => {
    const [step, setStep] = useState(1);
    const [quoteType, setQuoteType] = useState(null);
    const [clientType, setClientType] = useState(null);

    // DataPiker
    const [isDateFocused, setIsDateFocused] = useState(false);
    const [isStartTimeFocused, setIsStartTimeFocused] = useState(false);
    const [isEndTimeFocused, setIsEndTimeFocused] = useState(false);

    const datePickerRef = useRef(null);
    const timeStartPickerRef = useRef(null);
    const timeEndPickerRef = useRef(null);

    
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            clientInfo:{
                nom:"",
                telephone:"",
                email:"",
            },
            studioDetails: {
                participants: 1,
                desiredDate: new Date(),
                endTime: new Date(),
                startTime: new Date(),
            }
        },
    });

    const clientInfoNom = watch("clientInfo.nom");
    const clientInfoEmail = watch("clientInfo.email");
    const clientInfoPhone = watch("clientInfo.telephone");

    const nextStep = useCallback(() => {
        if (!clientType && step === 2) {
            toast.message("Veuillez choisir le type de client avant de passer à l'étape suivante");
            return;
        }
        if ((!clientInfoNom || !clientInfoEmail || !clientInfoPhone) && step === 3) {
            toast.message("Veuillez remplir ces champs avant de passer à l'étape suivante");
            return;
        }
        setStep((prev) => prev + 1);
    }, [clientType, clientInfoNom, clientInfoEmail, clientInfoPhone, step]); // Ajout des dépendances nécessaires
    
    const prevStep = useCallback(() => {
        setStep((prev) => prev - 1);
    }, []); // Pas de dépendance ici
    
    const handleQuoteTypeSelection = useCallback(
        (type) => {
            setQuoteType(type);
            nextStep();
        },
        [setQuoteType, nextStep]
    );

    const handleClientTypeSelection = (type) => {
        setClientType(type);
    };

    const selectedBudget = watch("promotionDetails.budget");
    const selectedServices = watch("promotionDetails.services");
    const selectedTypePrestation = watch("studioDetails.typePrestation");

    const studioParticipants = watch("studioDetails.participants")

    let navigate = useNavigate();

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const transformedData = {
            studioDetails: {
                // Format de la date souhaitée (en ISO) en utilisant .toISOString()
                desiredDate: data.studioDetails.desiredDate
                    ? format(data.studioDetails.desiredDate, "yyyy-MM-dd")  // Format ISO de la date
                    : null,

                // Extraction des heures et minutes depuis les objets Date pour startTime et endTime
                startTime: data.studioDetails.startTime
                    ? format(data.studioDetails.startTime, "HH:mm")  // Format des heures "HH:mm"
                    : null,

                endTime: data.studioDetails.endTime
                    ? format(data.studioDetails.endTime, "HH:mm")  // Format des heures "HH:mm"
                    : null,
            },
        };

        let formData = {};
        let validationErrors = [];

        if (quoteType === "promotion") {
            if (!data.promotionDetails.budget) validationErrors.push("Le budget est requis.");
            if (!data.promotionDetails.services) validationErrors.push("Les services sont requis.");
            if (!data.promotionDetails.target) validationErrors.push("La cible est requise.");

            formData = {
                type: "promotion",
                typeClient: clientType,
                fullName: data.clientInfo.nom,
                email: data.clientInfo.email,
                phone: data.clientInfo.telephone,
                budget: data.promotionDetails.budget,
                service: data.promotionDetails.services,
                target: data.promotionDetails.target,
            };
        }

        if (quoteType === "studio") {
            const typePrestation = data.studioDetails.typePrestation;
            let besoins = [];

            if (typePrestation === "Studio d'enregistrement") {
                besoins = data.studioDetails.besoinsEnregistrement || [];
            } else if (typePrestation === "Studio photo") {
                besoins = data.studioDetails.besoinsPhoto || [];
            } else if (typePrestation === "Studio de tournage vidéo") {
                besoins = data.studioDetails.besoinsVideo || [];
            } else if (typePrestation === "Événement privé") {
                besoins = data.studioDetails.besoinsTechniquesPrivate || [];
            } else if (typePrestation === "Événement d'entreprise") {
                besoins = data.studioDetails.besoinsEntreprise || [];
            } else if (typePrestation === "Studio de streaming") {
                besoins = data.studioDetails.besoinsDiffusion || [];
            } else {
                besoins = "Aucun besoin specifique"
            }

            if (!data.studioDetails.typePrestation) validationErrors.push("Le type de prestation est requis.");
            if (!data.studioDetails.participants) validationErrors.push("Le nombre de participants est requis.");
            if (!data.studioDetails.desiredDate) validationErrors.push("La date et l'heure souhaitées sont requises.");

            formData = {
                type: "studio",
                typeClient: clientType,
                fullName: data.clientInfo.nom,
                email: data.clientInfo.email,
                phone: data.clientInfo.telephone,
                service: data.studioDetails.typePrestation,
                participants: data.studioDetails.participants,
                desiredDate: transformedData.studioDetails.desiredDate,
                timeStarted: transformedData.studioDetails.startTime,
                timeEnded:  transformedData.studioDetails.endTime,
                techDetails: besoins,
            };
        }

        // Afficher les erreurs si elles existent
        if (validationErrors.length > 0) {
            return toast.message("Votre devis est presque prêt ! Il manque juste quelques infos, peut-être laissées dans une autre étape. 🎯")
        }

        try {

            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/quotes`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ formData }),
            });

            const resp = await res.json();

            let messageError = "";

            if (!resp.success) {
                messageError = resp.message;

                return toast.message(messageError);
            }

            toast.success("Votre devis a bien été envoyé chez Mobalicorp.");
            return navigate("/");

        } catch (error) {
            console.error(error);
            return toast.error("Quelque chose s'est mal déroulé, veuillez réessayer plus tard.");
        }
    };

    //Datapicker
    const handleToggleDatePicker = () => {
        if (datePickerRef.current) {
            if (isDateFocused) {
                // Blur the DatePicker if it is already focused
                datePickerRef.current.setBlur();
                setIsDateFocused(false);
            } else {
                // Focus the DatePicker if it is not focused
                datePickerRef.current.setFocus();
                setIsDateFocused(true);
            }
        }
    };

    const handleToggleTimeStartPicker = () => {
        if (datePickerRef.current) {
            if (isStartTimeFocused) {
                // Blur the DatePicker if it is already focused
                timeStartPickerRef.current.setBlur();
                setIsStartTimeFocused(false);
            } else {
                // Focus the DatePicker if it is not focused
                timeStartPickerRef.current.setFocus();
                setIsStartTimeFocused(true);
            }
        }
    };

    const handleToggleTimeEndPicker = () => {
        if (datePickerRef.current) {
            if (isEndTimeFocused) {
                // Blur the DatePicker if it is already focused
                timeEndPickerRef.current.setBlur();
                setIsEndTimeFocused(false);
            } else {
                // Focus the DatePicker if it is not focused
                timeEndPickerRef.current.setFocus();
                setIsEndTimeFocused(true);
            }
        }
    };

    return (
        <form className="xl:w-8/12 w-11/12 text-slate-700 flex flex-col gap-10" onSubmit={handleSubmit(onSubmit)}>

            <div>
                <ul className={`flex items-center lg:gap-4 gap-2`}>
                    {stepperList.map(item =>
                        <li
                            key={item.id}
                            className={`flex items-center gap-2 lg:text-sm text-xs text-slate-500`}
                        >
                            <span className={`lg:text-sm text-xs ${(step > item.id ) ? "bg-green-200 text-green-700" : (step === item.id ) ? "bg-orange-400 text-slate-50" : " text-slate-400 bg-slate-100"} lg:h-8 lg:w-8 h-4 w-4   flex items-center justify-center rounded-full`}><FiCheck/></span>
                            {item.title}
                        </li>
                    )}
                </ul>
            </div>

            {/* Step 1: Quote Type Selection */}

            <div>
                {step === 1 && (
                    <div className="flex flex-col gap-4">
                        <div className="lg:max-w-96 flex flex-col gap-2 mb-8">
                            <Heading typeHeading="h3" content="Quel est votre besoin ?" color="text-slate-700"/>
                            <Paragraph content="Promotion ou réservation de studio ? Faites votre choix pour que nous puissions vous offrir un service sur mesure, adapté à votre projet." />
                        </div>
                        <ul className="flex gap-4 flex-wrap">
                            <li>
                                <button
                                    type="button"
                                    className="hover:bg-slate-100 transition ease-in border border-slate-200 rounded-sm h-8 w-full flex items-center px-4 text-sm text-slate-500 justify-center"
                                    onClick={() => handleQuoteTypeSelection("promotion")}
                                >Devis Promotion
                                </button>
                            </li>
                            <li type="button">
                                <motion.button className=" hover:bg-slate-100 transition ease-in border border-slate-200 rounded-sm h-8 w-full flex items-center px-4 text-sm text-slate-500 justify-center" onClick={() => handleQuoteTypeSelection("studio")}>
                                    Devis Réservation de Studio
                                </motion.button>
                            </li>
                        </ul>
                    </div>
                )}

                {/* Step 2: Client Type Selection */}
                {step === 2 && (
                    <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                        <div className="lg:max-w-96 flex flex-col gap-2 mb-8">
                            <Heading typeHeading="h3" content="Qui êtes-vous ?" color="text-slate-700"/>
                            <Paragraph content="Particulier ou entreprise ? Indiquez-nous votre profil pour que nous puissions personnaliser notre approche et répondre à vos attentes avec efficacité."/>
                        </div>
                        <ul className="flex gap-4">
                            <li>
                                <button type="button"
                                        className={`border border-slate-200 rounded-sm h-8 w-full flex items-center px-4 text-sm justify-center ${clientType === "particulier" ? "bg-orange-400 text-slate-50" : " hover:bg-slate-100 transition ease-in bg-transparent text-slate-500"}`}
                                        onClick={() => handleClientTypeSelection("particulier")}>
                                    Particulier
                                </button>
                            </li>
                            <li>
                                <button type="button"
                                        className={`border border-slate-200 rounded-sm h-8 w-full flex items-center px-4 text-sm justify-center ${clientType === "entreprise" ? "bg-orange-400 text-slate-50" : "hover:bg-slate-100 transition ease-in bg-transparent text-slate-500"}`}
                                        onClick={() => handleClientTypeSelection("entreprise")}>
                                    Entreprise
                                </button>
                            </li>
                        </ul>
                    </div>
                )}

                {/* Step 3: Personal Information */}
                {step === 3 && (
                    <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                        <div className="lg:max-w-96 flex flex-col gap-2 mb-8">
                            <Heading typeHeading="h3" content="Faisons connaissance !" color="text-slate-700"/>
                            <Paragraph content="Vos informations nous permettent de mieux vous accompagner. Rassurez-vous, elles sont protégées et utilisées uniquement pour vous offrir le meilleur service."/>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2 lg:flex-row flex-col">
                                <label
                                    className={`lg:w-1/2 w-full h-12 rounded-sm ${errors.clientInfo?.nom ? "ring ring-red-400 ring-2" : "ring-none border border-slate-200"}`}>
                                    <input
                                        className={`w-full h-12 px-2 rounded-sm text-slate-500 text-base ${errors.clientInfo?.nom ? "placeholder:text-red-400" : "placeholder:text-slate-500"}`}
                                        type="text"
                                        placeholder={errors.clientInfo?.nom ? errors.clientInfo.nom.message : clientType === "entreprise" ? "Nom de l'organisme" : "Entrer votre nom"}
                                        {...register("clientInfo.nom", {required: "Le nom est requis."})}
                                    />
                                </label>
                                <label
                                    className={`lg:w-1/2 w-full h-12 rounded-sm ${errors.clientInfo?.email ? "ring ring-red-400 ring-2" : "ring-none border border-slate-200"}`}>
                                    <input
                                        className={`w-full h-12 px-2 rounded-sm text-slate-500 text-base ${errors.clientInfo?.email ? "placeholder:text-red-400" : "placeholder:text-slate-500"}`}
                                        type="email"
                                        placeholder={errors.clientInfo?.email ? errors.clientInfo.email.message : "Email"}
                                        {...register("clientInfo.email", {required: "Email requis"})}
                                    />
                                </label>
                            </div>

                            <label
                                className={`w-full rounded-sm h-12 ${errors.clientInfo?.telephone ? "ring ring-red-400 ring-2" : "ring-none border border-slate-200"}`}>
                                <input
                                    className={`w-full h-12 px-2 rounded-sm text-slate-500 text-base ${errors.clientInfo?.telephone ? "placeholder:text-red-400" : "placeholder:text-slate-500"}`}
                                    type="tel"
                                    placeholder={errors.clientInfo?.telephone ? errors.clientInfo.telephone.message : "Numéro de téléphone"}
                                    {...register("clientInfo.telephone", {required: "Téléphone requis"})}
                                />
                            </label>
                        </div>
                    </div>
                )}

                {/* Step 4: Quote Details Promo */}
                {step === 4 && quoteType === "promotion" && (
                    <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                        <div className="lg:max-w-96 flex flex-col gap-2 mb-8">
                            <Heading typeHeading="h3" content="Affinez votre demande" color="text-slate-700"/>
                            <Paragraph content="Ajoutez les informations clés pour finaliser votre devis : type de projet, besoins spécifiques, et toute autre précision importante."/>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold flex flex-wrap items-center gap-2">Services souhaités
                                    : {errors.promotionDetails?.services && <span
                                        className="text-xs text-red-400 font-medium">Ce champ est obligatoire</span>}</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Gestion des réseaux sociaux", "Publicité en 360 °", "Création de contenu", "Publicité (Advertising) ADS", "Campagne marketing tiktok"].map((service) => (
                                        <li key={service} className={`rounded-sm h-8 flex items-center justify-start px-2 relative 
                        ${selectedServices === service ? "bg-orange-400 text-slate-50 border-none" : "text-slate-500 bg-transparent border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-xs w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="radio"
                                                    {...register("promotionDetails.services", {required: true})}
                                                    value={service}
                                                />
                                                {service}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Budget estimé : {errors.promotionDetails?.budget &&
                                    <span className="text-xs text-red-400 font-medium">Ce champ est obligatoire</span>}</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Moins de 500 €", "500 € - 1000 €", "1000 € - 5000 €", "Plus de 5000 €"].map((budget) => (
                                        <li
                                            key={budget}
                                            className={`rounded-sm h-8 flex items-center justify-start px-2 relative 
                        ${selectedBudget === budget ? "bg-orange-400 text-slate-50 border-none" : "text-slate-500 bg-transparent border border-slate-300"}`}
                                        >
                                            <label className="flex items-center gap-2 text-xs w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="radio"
                                                    {...register("promotionDetails.budget", {required: true})}
                                                    value={budget}
                                                />
                                                {budget}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="flex flex-col gap-2">
                                <label className="text-sm font-semibold">Objectifs de la campagne
                                    : {errors.promotionDetails?.services && <span
                                        className="text-xs text-red-400 font-medium">Ce champ est obligatoire</span>}</label>
                                <textarea
                                    placeholder="Objectifs"
                                    {...register("promotionDetails.target")}
                                    className="h-24 w-full p-2 text-sm border border-slate-200 rounded-sm focus:outline-none"
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Step 4: Quote Details Studio */}
                {step === 4 && quoteType === "studio" && (
                    <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                        <div className="lg:max-w-96 flex flex-col gap-2 mb-8">
                            <Heading typeHeading="h3" content="Affinez votre demande" color="text-slate-700"/>
                            <Paragraph content="Ajoutez les informations clés pour finaliser votre devis : type de projet, besoins spécifiques, et toute autre précision importante."/>
                        </div>
                        <div className="flex flex-col gap-4 pb-10">
                            <div className="flex flex-col gap-2">
                                <p className={`font-semibold text-sm ${errors.studioDetails?.endTime && !getValues("studioDetails.endTime") ? "text-red-500" : "text-slate-700"}`}>Type
                                    de prestation</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Studio d'enregistrement", "Studio photo", "Studio de tournage vidéo", "Événement privé", "Événement d'entreprise", "Studio de streaming"].map((typePrestation) => (
                                        <li
                                            key={typePrestation}
                                            className={`rounded-sm h-8 flex items-center justify-start px-2 relative 
                            ${selectedTypePrestation === typePrestation ? "bg-orange-400 text-slate-50 border-none" : "text-slate-500 bg-transparent border border-slate-300"}`}
                                        >
                                            <label
                                                className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                <input
                                                    className="sr-only"
                                                    type="radio"
                                                    {...register("studioDetails.typePrestation", {required: true})}
                                                    value={typePrestation}
                                                />
                                                {typePrestation}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div
                                className="order-first flex flex-col gap-2 border border-slate-300 rounded-sm p-6 items-start">
                                <label htmlFor="desiredDate" className="flex items-center gap-4 w-full">
                                <span
                                    className={`w-1/3 grow text-sm ${errors.studioDetails?.desiredDate ? "text-red-500" : "text-slate-700"}`}>
                                    À quelle date souhaitez-vous réserver ?
                                </span>
                                    <Controller
                                        name="studioDetails.desiredDate"
                                        control={control}
                                        render={({field}) => (
                                            <div className="relative">
                                                <DatePicker
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={fr}
                                                    className="max-w-24 bg-transparent"
                                                    ref={datePickerRef}
                                                />
                                            </div>

                                        )}
                                    />

                                    <Button icon={<MdOutlineCalendarToday/>} handler={handleToggleDatePicker}/>
                                </label>

                                {/* DatePicker pour l'heure de début */}
                                <label htmlFor="startTime" className="flex items-center gap-4 w-full">
                                <span
                                    className={`w-1/3 grow text-sm ${errors.studioDetails?.startTime ? "text-red-500" : "text-slate-700"}`}>
                                    Heure de début
                                </span>
                                    <Controller
                                        name="studioDetails.startTime"
                                        control={control}
                                        render={({field}) => (
                                            <div className="relative">
                                                <DatePicker
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Début"
                                                    dateFormat="HH:mm"
                                                    locale={fr}
                                                    className="bg-transparent max-w-16 "
                                                    ref={timeStartPickerRef}
                                                />
                                            </div>

                                        )}
                                    />
                                    <Button icon={<MdOutlineAccessTime/>} handler={handleToggleTimeStartPicker}/>
                                </label>

                                {/* DatePicker pour l'heure de fin */}
                                <label htmlFor="endTime" className="flex items-center gap-4 w-full">
                                <span
                                    className={`w-1/3 grow text-sm ${errors.studioDetails?.endTime ? "text-red-500" : "text-slate-700"}`}>
                                    Heure de fin
                                </span>
                                    <Controller
                                        name="studioDetails.endTime"
                                        control={control}
                                        render={({field}) => (
                                            <div className="relative">
                                                <DatePicker
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Fin"
                                                    dateFormat="HH:mm"
                                                    locale={fr}
                                                    className="bg-transparent max-w-16 "
                                                    ref={timeEndPickerRef}
                                                />
                                            </div>
                                        )}
                                    />
                                    <Button icon={<MdOutlineAccessTime/>} handler={handleToggleTimeEndPicker}/>
                                </label>

                                <hr className="w-full"/>

                                <label className="flex items-center gap-4 w-full">
                                <span
                                    className={`w-1/3 grow text-sm ${errors.studioDetails?.participants && !watch("studioDetails.startTime") ? "text-red-500" : "text-slate-700"}`}>Combien de participants seront présents ?</span>
                                    <input
                                        type="number"
                                        {...register("studioDetails.participants")}
                                        className="w-1/4 h-12 px-2 border border-slate-200 bg-slate-100 hidden"
                                    />
                                    <div className="flex items-center gap-2">
                                    <span
                                        className="text-slate-500 font-medium w-4">{studioParticipants && getValues("studioDetails.participants")}</span>
                                        <button
                                            type="button"
                                            className="text-xs text-slate-500 border border-slate-700 rounded-full w-6 h-6 flex items-center justify-center"
                                            onClick={() =>
                                                setValue(
                                                    "studioDetails.participants",
                                                    Math.max(1, getValues("studioDetails.participants") - 1)
                                                )
                                            }
                                        >
                                            <FiArrowLeft/>
                                        </button>
                                        <button
                                            type="button"
                                            className="text-xs text-slate-500 border border-slate-700 rounded-full w-6 h-6 flex items-center justify-center"
                                            onClick={() =>
                                                setValue(
                                                    "studioDetails.participants",
                                                    getValues("studioDetails.participants") + 1
                                                )
                                            }
                                        >
                                            <FiArrowRight/>
                                        </button>
                                    </div>
                                </label>
                            </div>

                            {/* Besoins techniques spécifiques */}
                            {selectedTypePrestation === "Studio d'enregistrement" && (
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-semibold">Besoins techniques pour Studio d'enregistrement
                                        :</p>
                                    <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                        {["Microphones de haute qualité", "Table de mixage", "Isolation phonique", "Équipement pour voix-off", "Enregistrement multi-pistes"]?.map((besoin) => (
                                            <li key={besoin}
                                                className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsEnregistrement")) && watch("studioDetails.besoinsEnregistrement")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                                <label
                                                    className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                    <input
                                                        className="sr-only"
                                                        type="checkbox"
                                                        {...register("studioDetails.besoinsEnregistrement")}
                                                        value={besoin}
                                                    />
                                                    {besoin}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {selectedTypePrestation === "Studio photo" && (
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-semibold">Besoins techniques pour Studio photo :</p>
                                    <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                        {["Éclairage professionnel", "Toile de fond", "Appareils photo HD", "Trépieds", "Accessoires pour séance photo"]?.map((besoin) => (
                                            <li key={besoin}
                                                className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsPhoto")) && watch("studioDetails.besoinsPhoto")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                                <label
                                                    className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                    <input
                                                        className="sr-only"
                                                        type="checkbox"
                                                        {...register("studioDetails.besoinsPhoto")}
                                                        value={besoin}
                                                    />
                                                    {besoin}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {selectedTypePrestation === "Studio de tournage vidéo" && (
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-semibold">Besoins techniques pour Studio de tournage vidéo
                                        :</p>
                                    <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                        {["Caméras 4K", "Éclairage vidéo", "Fond vert", "Équipement de stabilisation", "Post-production"]?.map((besoin) => (
                                            <li key={besoin}
                                                className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsVideo")) && watch("studioDetails.besoinsVideo")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                                <label
                                                    className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                    <input
                                                        className="sr-only"
                                                        type="checkbox"
                                                        {...register("studioDetails.besoinsVideo")}
                                                        value={besoin}
                                                    />
                                                    {besoin}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {selectedTypePrestation === "Événement privé" && (
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-semibold">Besoins techniques pour un événement privé :</p>
                                    <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                        {["Sonorisation", "Éclairage d'ambiance", "Projecteurs", "Caméras de diffusion"]?.map((besoin) => (
                                            <li key={besoin}
                                                className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsTechniquesPrivate")) && watch("studioDetails.besoinsTechniquesPrivate")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                                <label
                                                    className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                    <input
                                                        className="sr-only"
                                                        type="checkbox"
                                                        {...register("studioDetails.besoinsTechniquesPrivate", {required: false})}
                                                        value={besoin}
                                                    />
                                                    {besoin}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {selectedTypePrestation === "Événement d'entreprise" && (
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-semibold">Besoins techniques pour un événement d'entreprise
                                        :</p>
                                    <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                        {["Sonorisation", "Éclairage professionnel", "Caméra de diffusion en direct", "Écran géant"]?.map((besoin) => (
                                            <li key={besoin}
                                                className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsEntreprise")) && watch("studioDetails.besoinsEntreprise")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                                <label
                                                    className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                    <input
                                                        className="sr-only"
                                                        type="checkbox"
                                                        {...register("studioDetails.besoinsEntreprise", {required: false})}
                                                        value={besoin}
                                                    />
                                                    {besoin}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {selectedTypePrestation === "Studio de streaming" && (
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-semibold">Besoins techniques pour Studio de streaming :</p>
                                    <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                        {["Casque audio", "Caméra pour diffusion en direct", "Éclairage vidéo", "Système audio de qualité"]?.map((besoin) => (
                                            <li key={besoin}
                                                className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsDiffusion")) && watch("studioDetails.besoinsDiffusion")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                                <label
                                                    className="flex items-center gap-2 text-sm w-full cursor-pointer text-xs">
                                                    <input
                                                        className="sr-only"
                                                        type="checkbox"
                                                        {...register("studioDetails.besoinsDiffusion", {required: false})}
                                                        value={besoin}
                                                    />
                                                    {besoin}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Submit Button */}
                {step !== 1 &&
                    <div className="lg:absolute lg:bottom-10 xl:w-8/12 gap-10 relative lg:my-0 my-10  flex items-center justify-between">
                        <button
                            type="button"
                            onClick={prevStep}
                            className="h-10 border border-slate-300 p-2 rounded-sm text-sm"
                        >Précédent
                        </button>
                        {step !== 4 ?
                            <button
                                type="button"
                                onClick={nextStep}
                                className="h-10 bg-orange-400 text-slate-50 p-2 rounded-sm text-sm"
                            >
                                Continuer
                            </button>
                            :
                            <button
                                type="submit"
                                className={`h-10 bg-orange-400 text-slate-50 p-2 rounded-sm text-sm`}>Créer une
                                reservation
                            </button>
                        }
                    </div>
                }
            </div>
        </form>
    );
};

export default Stepper;

const stepperList = [
    {
        id:1,
        title:"Besoin"
    },
    {
        id:2,
        title:"Client"
    },
    {
        id:3,
        title:"Informations"
    },
    {
        id:4,
        title:"Details"
    }
]

