import {motion} from "framer-motion";
import ImageTest from "../../assets/images/back_03.webp"

const Image = ({ image, alt, custWidth, url, rounded=true, cover=true }) => {
    return (
        <motion.div
            className={`${custWidth ? custWidth : "w-96"} overflow-hidden`}
            initial={{ scale:1 }}
            whileHover={{scale: .99 }}
            
            transition={{
                type: "spring",
                stiffness: 100,
                damping: 15,
                duration: 0.6,
            }}

            viewport={{ amount: .5 } }
        >
            <figure className={`${custWidth ? custWidth : "w-96"}`}>
                <img
                    src={image ? url ? image : `/uploads/${image}.webp`: ImageTest}
                    alt={alt ? alt : "Cette photo n'a pas de description propre"}
                    className={`w-full h-full ${ rounded ? "rounded-2xl":"rounded-none"} shadow-2xl ${ cover ? "object-cover" : "object-contain"}`}
                />
            </figure>
        </motion.div>
    );
};

export default Image;
