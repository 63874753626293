import {BackButton} from "../../../ui/button/BackButton";
import DisplayItem from "../component/atoms/DisplayItem";
import BannerPurple from "../../../ui/atoms/BannerPurple";
import { useEffect } from "react";
import { useContext } from "react";
import { LoadingPage } from "../../../ui/LoadingPage";
import { LoadingContext } from "../../../context/loadingContext";

const DisplayActus = () => {
    const { loading, startLoading } = useContext(LoadingContext);
    
    useEffect(() => {
        startLoading();
    }, [startLoading]);

    if (loading) return <LoadingPage isText={`Actus en cours`} />;

    return (
        <article className={`lg:min-h-screen lg:py-10 pb-10 w-full`}>
            <div className="z-10 lg:w-8/12 w-full m-auto relative min-h-screen flex flex-col gap-10 lg:items-center lg:pt-0 lg:justify-center">
                <DisplayItem/>
                
                <BackButton color="text-slate-700" path="actus"/>
            </div>
            <BannerPurple/>
        </article>
    )
}

export default DisplayActus;  