import { useParams } from "react-router-dom";
import { Forms } from "../../../ui/admin/Forms";

export const Edit = () => {
  const { slug } = useParams();

  const apiUrl = `${process.env.REACT_APP_API_URL}/api/posts/${slug}`;

  return (
    <article id="create-post">
      <div className="wrapper">
        <Forms url={apiUrl} methode="PUT" edit_ />
      </div>
    </article>
  );
};
