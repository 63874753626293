import { createContext, useState, useCallback } from 'react';

export const LoadingContext = createContext(false);

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    // Fonction pour lancer le chargement
    const startLoading = useCallback(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <LoadingContext.Provider value={{ loading, startLoading }}>
            {children}
        </LoadingContext.Provider>
    );
}

export default LoadingProvider;
