import { CheckIcon } from "../atoms/CheckIcon"

export const CheckText = ({ content, textCustom, color, primary }) => {
    return (
        <li className="flex items-start gap-2">
            <CheckIcon />
            <div className="grow w-10">
                    <p className={`2xl:text-xl text-sm ${color ? color : primary ? "text-slate-200" : "text-slate-700"}`}> <span className="font-bold">{textCustom ? textCustom : ""}</span> {content}</p>
            </div>
        </li>
    )
}