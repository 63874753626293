import { useEffect, useState } from 'react';
import { DataCompaign } from '../../../data/Data';

const useFetchCampaignBySlug = (slug) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        const handleFetchCampiagnBySlug = async () => {

            setIsLoading(true);

            try {
                const res = await DataCompaign?.find(item => item.slug === slug);

                if (!res) throw new Error("Ooops! nous n'avons pas reussi à trouver votre projet");

                setData(res);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setError(error);
                setIsLoading(false)
            }
        }

        handleFetchCampiagnBySlug();
        
    }, [slug]);

    return { data, isLoading, error }
};

export default useFetchCampaignBySlug;
