const Button = ({ handler, icon }) => {
    return (
        <button
            onClick={handler}
            type="button"
            className="flex items-center justify-center h-8 w-8 border border-slate-300 rounded-full text-slate-400 bg-slate-100 text-slate-700"
        >
            {icon}
        </button>
    );
};

export default Button;
