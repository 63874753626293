import { FiArrowRight } from "react-icons/fi";

export const LinkCustom = ({ label, url }) => {

  return (
    <a
      href={`${url}`}
      target="_blank"
      rel="noreferrer"
      className="text-orange-400 xl:max-w-96 lg:max-w-52 flex items-center gap-2 cursor-pointer group hover:text-orange-200 2xl:text-2xl text-sm transition ease-in-out duration-75"
    >
      {label}
      <span className="group-hover:translate-x-4 transition ease-in-out duration-200 text-orange-400">
        <FiArrowRight />
      </span>
    </a>
  );
};