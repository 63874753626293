import React from 'react';
import {motion} from "framer-motion";

const MyComponent = ({ label, icon, color, variant, border, rounded, size, handler, title }) => {

    let isColor, isRounded, isSize;

    switch (color) {
        case 'primary':
            isColor = (variant === "contained") ? 'text-slate-50 bg-orange-500' : "text-slate-500 border-slate-400 bg-transparent";
            break;
        case 'secondary':
            isColor = (variant === "contained") ? 'text-slate-50 bg-slate-700' : "text-slate-500 border-slate-400 bg-transparent";
            break;
        default:
            isColor = (variant === "outlined") ? "text-slate-50 bg-transparent" : "text-slate-500 border-slate-400 bg-slate-50" ;
            break;
    }

    switch (rounded) {
        case 'full':
            isRounded = "rounded-full";
            break;
        case 'small':
            isRounded = "rounded-sm";
            break;
        default:
            isRounded = 'rounded-md';
            break;
    }

    switch (size) {
        case 'small':
            isSize = rounded === "full" ? "h-8 w-8" : "h-10 px-2";
            break;
        case 'medium':
            isSize = rounded === "full" ? "h-14 w-14" : "h-14 px-2";
            break;
        case 'large':
            isSize = rounded === "full" ? "h-16 w-16" : "h-16 px-2";
            break;
        default:
            isSize = rounded === "full" ? "h-10 w-10" : "h-12 px-2";
            break;
    }

    const activeBorder = border ? "border" : "border-none"

    const handleClick = () => {
        if (!handler) return ;
        handler()
    }

    return (
        <motion.button
            whileTap={{ scale: 1.045}}
            whileHover={{ scale: .955 }}

            transition={{ type:"spring", duration:.3, ease:"linear"}}
            className={`${isRounded} ${isColor} ${activeBorder} ${isSize} flex items-center justify-center group hover:border-none hover:bg-orange-400 transition ease-linear`}
            onClick={() => handleClick()}
            title={title}
        >
            {label && label}
            {icon && <span className={`lg:text-xl text-md group-hover:text-slate-50 transition ease-linear`}>{icon}</span>}
        </motion.button>
    );
};

export default MyComponent;
