import { motion } from "framer-motion";

export const Element = ({ title, className, rotate, rounded }) => {
    return (
        <motion.p 
            className={`${rounded ? "rounded-full h-16 w-16" : "rounded-sm px-8 h-20" } 2xl:text-5xl xl:text-4xl text-2xl font-black bg-gradient-to-t from-40% from-orange-400 to-rose-400 text-slate-50  flex items-center justify-center ${className}`}
            initial={{ y: 15 }}
            whileInView={{ y: 0, scale: 1.1, rotate: rotate }}
            transition={{ type: "spring", stiffness: 100, damping: 15, duration: 0.6 }}
        >
            {title}
        </motion.p>
    )
}