import { FiEye, FiHeart } from "react-icons/fi"
import { GradientOverlay } from "../../../../ui/about/atoms/GradientOverlay"
import Image from "../../../../ui/atoms/Image"
import Paragraph from "../../../../ui/atoms/Paragraph"
import { Button } from "../../../../ui/button/Button"
import { getGradientPosition } from "../../../../utils/getGradientPosition"
import { truncateTextByWords } from "../../../../utils/truncateTextByWords"
import Heading from "../../../../ui/atoms/Heading"
import { Container } from "../../../../ui/atoms/Container"
import { LazyVideo } from "../../../projects/component/atoms/LazyVideo"
import { BackButton } from "../../../../ui/button/BackButton";
import { LinkCustom } from "../../../../ui/atoms/LinkCustom";
import { FaSpotify, FaTiktok } from "react-icons/fa"
import { motion } from "framer-motion"

export const UniqueCompaign = ({ data, display=false }) => {

    return (
        <motion.article 
            className={`${!display ? "lg:h-[80vh] 2xl:pt-72 lg:pt-32 py-0" : "min-h-screen " } w-full flex flex-col items-center`}
            
            initial={{ opacity:0 }}
            animate={{ opacity: 1 }}  
            
            transition={{ type:"spring", duration:.5, ease:"easeIn" }}
        >
            { display && <BackButton path="campagnes" />}
            <Container className="flex lg:flex-row flex-col items-stretch h-full">
                <div className="lg:w-1/2 w-full grow flex items-center lg:py-0 py-10">
                    <div className="min-h-96 w-full lg:pr-10 flex flex-col gap-2">
                        <Heading typeHeading="h2" size="2xl:text-7xl lg:text-4xl" content={data.bigTitle} className="lg:mb-4" />

                        {/* Infos */}
                        <ul className="flex gap-0 divide-x divide-slate-700 lg:mb-4">
                            {data?.like && 
                                <li className="pr-4"><p className="flex items-center 2xl:text-2xl  gap-2 text-slate-400">{data?.like.numb} <span className="text-slate-400 2xl:text-2xl text-sm"><FiHeart /></span></p></li>
                            }
                            {data?.view && 
                                <li className="px-4"><p className="flex items-center 2xl:text-2xl  gap-2 text-slate-400">{data?.view.numb} <span className="text-slate-400 2xl:text-2xl text-sm"><FiEye /></span></p></li>
                            }
                            {data?.tiktokcontentcreator && 
                                <li className="px-4"><p className="flex items-center 2xl:text-2xl  gap-2 text-slate-400">{data?.tiktokcontentcreator.numb} <span className="text-slate-400 2xl:text-2xl text-sm"><FaTiktok /></span></p></li>
                            }
                            {data?.stream && 
                                <li className="px-4"><p className="flex items-center 2xl:text-2xl  gap-2 text-slate-400">{data?.stream.numb} <span className="text-slate-400 2xl:text-2xl text-sm"><FaSpotify /></span></p></li>
                            }
                        </ul>
                        <Paragraph primary content={ display ? data?.resume :  truncateTextByWords(data?.resume, 25)} customStyle="mb-10"/>
                        <span className="text-sm order-first text-orange-400">{data?.title}</span>
                        
                        { !display ? 
                            <Button customWidth="md:w-fit px-10 2xl:h-20 h-14 2xl:text-2xl text-sm w-full" icon namebtn={display ? "Voir la campagne" : "Découvrir"} action path={`campagnes/${data?.slug}`}/>
                            :
                            <>
                                { data?.url && 
                                    <LinkCustom label="Voir la campagnes" url={data.url} />
                                }
                            </>
                            
                            
                        }
                        
                    </div>
                </div>

                <div className="lg:w-1/2 w-full lg:order-last order-first flex items-center justify-center relative">
                    
                    {display ? 
                        <>
                            {(data.mainImage && !data.video) &&
                                <Image
                                    rounded={false}
                                    url
                                    cover={false}
                                    image={`/uploads/project/${data.mainImage}.webp`}
                                    custWidth={display ? "lg:h-screen h-[550px]" : "lg:h-[80vh] h-[550px]"}
                                />
                            }


                            { data.video &&

                                <LazyVideo 
                                    videoSrc={`https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/video/${data.video}`}
                                    poster={`/uploads/project/${data.mainImage}.webp`}
                                />
                            }
                        </>
                        :
                        <>
                            <Image
                                rounded={false}
                                url
                                image={`/uploads/project/${data.mainImage}.webp`}
                                custWidth="lg:h-full h-[550px]"
                            />
                            {["to-b", "to-t", "to-r", "to-l"].map((direction, index) => (
                                <GradientOverlay
                                    key={index}
                                    position={getGradientPosition(direction)}
                                    direction={`bg-gradient-${direction} from-black from-30% to-transparent`}
                                />
                            ))}
                        </>
                        

                    }
                    
                    
                </div>
            </Container>
        </motion.article>
    )
}