export const Article = ({ children, className, style }) => {

    return (
        <article 
            className={`${className} min-h-screen `}
            style={style}
        >
            { children }
        </article>
    )
}