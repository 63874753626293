import { Navigate, Outlet   } from "react-router-dom"
import { Sidebar } from "../partials/Sidebar"
import { AuthContext } from "../context/AuthContext"
import { useContext } from "react"
import { LoadingPage } from "../ui/LoadingPage"

export const LayoutAdmin = () => {
    const { isLoggedIn, isLoading } = useContext(AuthContext);

    if (isLoading) {
        return <LoadingPage />
    }

    if (!isLoggedIn) {
        return <Navigate to="/mk-login" replace={true} />
    }
    
    return (
        <div id="admin">
            <Sidebar />
            <div className="wrapper_admin relative">
                <Outlet />
            </div>   
        </div>
    )
}