import { motion } from "framer-motion"

export const HeroText = ({ content, className, color }) => {
    
    return (
        <motion.p
            className={`${className} ${color ? color : "text-slate-200"} 2xl:text-7xl lg:text-5xl text-2xl  font-black leading-snug z-20`}
            initial={{opcity: 0, translateY:55}}
            whileInView={{opacity: 1, translateY:0}}
            
            transition={{type: "spring", stiffness: 200, damping: 50}}
        >
            { content }
        </motion.p>
    )
}