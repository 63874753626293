import {useContext, useEffect, useState} from 'react';
import Banner from "../ui/home/Banner";
import About from "../ui/about/About";
import { Container } from "../ui/about/atoms/Container";
import {LoadingContext} from "../context/loadingContext";
import {LoadingPage} from "../ui/LoadingPage";
import Image from '../ui/atoms/Image';
import Heading from '../ui/atoms/Heading';
import Paragraph from '../ui/atoms/Paragraph';
import { DevisButton } from '../ui/atoms/DevisButton';
import { CheckText } from '../ui/organisms/CheckText';
import backAb from "../assets/images/back-ab.webp";

const LandingPage = () => {
    const [scrollY, setScrollY] = useState(0);
    const { loading, startLoading } = useContext(LoadingContext);

    useEffect(() => {
        startLoading();
    }, [startLoading]);

    useEffect(() => {
        const handleScroll = () => setScrollY(window.scrollY);
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollY]);

    if (loading) return <LoadingPage />;

    return (
        <>
            <Banner/>
            <About/>
            <article className="lg:h-screen w-full bg-slate-50 relative flex lg:flex-row flex-col lg:gap-0 lg:pt-0 pt-10 gap-10">
                <Container className="h-full flex items-stretch ">
                    <div className="lg:w-1/2 w-full flex items-center">
                        <div className="flex w-full flex-col gap-4">
                            <Heading content="Prêt à transformer votre influence en résultats concrets ?" primary color size="2xl:text-7xl lg:text-5xl text-2xl" font="font-black" />

                            <div className="pb-4">
                                <ul className="flex flex-col gap-4 pr-10">
                                    <CheckText content="Du choix des influenceurs à l’optimisation des performances, chaque étape est pensée pour générer un impact mesurable."  />
                                    <CheckText content="Analyse, activation et amplification : nous orchestrons votre succès sur toutes les plateformes."  />
                                </ul>   
                            </div>

                            <div className="pt-4">
                                <Paragraph 
                                    content="Passez à l’action dès maintenant et faites de votre influence un levier de croissance !" 
                                    customStyle="lg:max-w-2xl"
                                    size="Small"
                                    color="text-slate-500"
                                />
                            </div>
                            <DevisButton label="Je passe à l'action!" />
                        </div>
                
                    </div>
                </Container>

                <div className="lg:w-1/3 w-full lg:absolute right-0 top-0 lg:h-screen">
                    <Image url={true} image={backAb} alt="image" rounded={false} custWidth="w-full lg:h-screen h-[500px]" />
                </div>
                
            </article>
        </>
    );
};

export default LandingPage;

