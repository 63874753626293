import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// Pages import routes
import PageNotFound from '../pages/PageNotFound';
import LayoutPage from '../layouts/LayoutPage';
import Actus from '../features/actus/pages/Actus';
import DisplayActus from '../features/actus/pages/DisplayActus';
import { useEffect } from 'react';
import { LayoutAdmin } from '../layouts/LayoutAdmin';
import { Dashboard } from '../features/admin/pages/Dashboard';
import { DashboardArticle } from '../ui/admin/DashboardArticle';
import { Login } from '../features/auth/pages/Login';
import { LayoutLogin } from '../layouts/LayoutLogin';
import { Create } from '../features/actus/pages/Create';
import { Users } from '../ui/admin/Users';
import { Edit } from '../features/actus/pages/Edit';
import { LayoutActus } from '../features/actus/LayoutActus';
import { Mentions } from '../pages/Mentions';
import Home from "../pages/Home";
import Devis from "../features/devis/pages/Devis";
import { isMaintenance } from "../Variables";
import Maintenance from "../pages/Maintenance";
import Services from '../pages/Services';
import { Studio } from '../pages/Studio';
import { Galeries } from '../pages/Galeries';
import { Campaign } from '../features/campaign/pages/Campaign';
import { DisplayCompaign } from '../features/campaign/pages/DisplayCampaign';
import { Apropos } from '../pages/Apropos';

const AnimatedRoutes = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [location.pathname]);

    return (
        <AnimatePresence mode="popLayout">
            <Routes location={location} key={location.pathname}>
                {isMaintenance ?
                    <Route path="*" element={<Maintenance/>}/>
                    :
                    <>
                        <Route element={<LayoutPage />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/lab" element={<Studio />} />

                            <Route path="/campagnes" element={<Campaign />} />
                            <Route path="/apropos" element={<Apropos/>} />
                        </Route>

                        <Route path="/lab/galeries" element={<Galeries />} />

                        <Route path="/mk-admin/*" element={<LayoutAdmin />}>
                            <Route index element={<Dashboard />} />
                            <Route path="mes-articles" element={<DashboardArticle />} />
                            <Route path="mes-articles/:slug" element={<Edit />} />
                            <Route path="creer" element={<Create />} />
                            <Route path="utilisateurs" element={<Users />} />
                        </Route>

                        <Route element={<LayoutActus />}>
                            <Route path="/actus/:slug" element={<DisplayActus />} />
                            <Route path="/actus" element={<Actus />} />
                            <Route path="/campagnes/:slug" element={<DisplayCompaign />} />
                            <Route path="/mentionslegales" element={<Mentions />} />
                        </Route>

                        <Route element={<LayoutLogin />}>
                            <Route path="/mk-login" element={<Login />} />
                            <Route path="/creerundevis" element={<Devis />} />
                        </Route>

                        <Route path="/*" element={<PageNotFound />} />
                    </>
                }
            </Routes>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;
