import React, {useEffect} from 'react';

const Paragraph = ({ content, size, primary, color, customStyle }) => {
    const [sizeClass, setSizeClass] = React.useState('');

    useEffect(() => {
        switch (size) {
            case "Small":
                setSizeClass("2xl:text-xl text-sm");
                break;
            case "Medium":
                setSizeClass("2xl:text-3xl text-base");
                break;
            case "Large":
                setSizeClass("2xl:text-4xl text-xl");
                break;
            default:
                setSizeClass("2xl:text-2xl text-base");
                break;
        }
    }, [sizeClass, size]);


    return (
        <p className={`${color ? color : primary ? "text-slate-200" : "text-slate-700"} ${sizeClass} ${customStyle}`}>
            {content}
        </p>
    );
};

export default Paragraph;
