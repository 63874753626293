const Heading = ({ typeHeading = "h2", content, primary= true, size, style, color, className, font }) => {

    switch (typeHeading) {
        case "h2":
            return <h2 className={`${size ? size : "text-3xl"} ${className} ${font ? font : "font-semibold"} ${ color ? color : primary ? "text-slate-200" : "text-slate-900"}`} style={style}>{content}</h2>;
        case "h3":
            return <h3 className={`${size ? size : "text-2xl"} ${className} ${font ? font : "font-medium "} ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h3>;
        case "h4":
            return <h4 className={`${size ? size : "text-xl"} ${className} ${font ? font : "font-medium "} ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h4>;
        case "h5":
            return <h5 className={`${size ? size : "text-lg"} ${className} ${font ? font : "font-normal "} ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h5>;
        case "h6":
            return <h6 className={`${size ? size : "text-base"} ${className} ${font ? font : "font-normal "} ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h6>;
        default:
            return <h1 className={`${ size ? size : "text-4xl" } ${className} ${font ? font : "font-medium "} ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h1>; // fallback to h1
    }
};

export default Heading;
