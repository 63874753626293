import { SideMenu } from "../ui/admin/SideMenu"

export const Sidebar = () => {
    return (
        <header className="sidebar">
            <div>
                <SideMenu />
            </div>
        </header>
    )
}