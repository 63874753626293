import { useNavigate } from "react-router-dom"
import Image from "../../atoms/Image"
import { FiArrowUpRight } from "react-icons/fi";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export const CompaignItem = ({ item, uniqueKey, type }) => {

    const navigate = useNavigate();
    const [customUrl, setCustomUrl] = useState(false);

    const handleClick = (slug) => {
        if (!slug) return;
        navigate(`/campagnes/${slug}`)
    }


    useEffect(() => {
        if (type === "compaign") {
            setCustomUrl(true)
        } else {
            setCustomUrl(false)
        }

    }, [customUrl, type])


    return (
        <li 
            key={uniqueKey} 
            className={`overflow-hidden cursor-pointer rounded-3xl 2xl:h-[65rem] 2xl:w-[60rem] lg:h-[45rem] lg:w-[32rem] w-[24rem] h-[40rem] relative`}

            onClick={() => handleClick(item.slug) }
        >
            { type === "services" ?

                <motion.div className={`bg-gradient-to-br from-orange-600 to-yellow-600 text-white p-6 xl:rounded-lg w-full h-full shadow-xl cursor-default relative overflow-hidden flex items-center`}
                    >
                    {item.icon && <span className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" >{item.icon}</span>}
                        <div className="relative z-10">
                    { item.icon && 
                        <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-orange-600 grid place-items-center mx-auto">
                            {item.icon}
                        </div> 
                    }
                        <h3 className="xl:text-4xl font-bold text-center mb-2">
                        {item.title}
                        </h3>
                        <p className="text-center xl:text-2xl lg:text-xl leading-snug text-lg mb-6">
                            {item.content}
                        </p>
                    </div>
                </motion.div>
                :
                <Image alt={`campagne ${uniqueKey}`} image={`${customUrl ? `/uploads/project/${item.mainImage}.webp` : item.mainImage }`} url={customUrl} custWidth={`2xl:h-[65rem] 2xl:w-[60rem] lg:w-[32rem] lg:h-[45rem] w-[24rem] h-[40rem] object-cover`}/>  
            }

            { type === "compaign" && 
                <div className="absolute top-0 left-0 p-4 w-full">
                    <p className="flex items-center gap-4"> <span className="rounded-full bg-slate-50/90 h-10 flex items-center px-4">{item.title}</span> <span className="bg-slate-50/70 text-slate-slate-900 rounded-full h-10 w-10 flex items-center justify-center"><FiArrowUpRight /></span></p>
                </div>
            }            
        </li>
        
    )
}