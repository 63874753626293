import { useRef } from "react"
import { motion, useScroll, useTransform } from "framer-motion"

export const ParagraphAnimated = ({ value }) => {
    const ref = useRef()

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start 0.7", "start .25"], // Augmente la plage de déclenchement
        smooth: 1.5 // Ajoute un lissage supplémentaire
    })

    const words = value.split(" ")

    return (
        <p ref={ref} className="text-2xl text-slate-700 font-medium flex flex-wrap dancingfont">
            {words.map((word, i) => {
                const start = i / words.length
                const end = start + (1.5 / words.length) // Étend la durée d'animation par mot
                return (
                    <Word 
                        key={i} 
                        range={[start, end]} 
                        progress={scrollYProgress}
                    >
                        {word}
                    </Word>
                )
            })}
        </p>
    )
}

const Word = ({ children, range, progress }) => {

    const opacity = useTransform(progress, range, [0, 1])

    return (
        <span className="mr-2 mt-2 relative overflow-hidden inline-block">
            <span className="absolute opacity-5">{children}</span>
            <motion.span 
                className="inline-block"
                style={{ 
                    opacity: opacity, 
                    transition: "all 0.6s cubic-bezier(0.16, 1, 0.3, 1)" 
                }}
            >
            {children}
            </motion.span>
        </span>
    )
}