// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import Item from "../../features/projects/component/atoms/Item";
import { useMediaQuery } from "react-responsive";
import { ParallaxService } from '../services/atoms/ParallaxService';
import Image from '../atoms/Image';

const SwiperCustom = ({ data, type = "project", nbSlide, url, normal = true }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 868px)" });

    const swiperSettings = {
        slidesPerView: isMobile ? 1 : nbSlide ? nbSlide : 4,
        spaceBetween: 10,
        navigation: true,
        pagination:false,
        modules: [Pagination, Navigation],
        className: `mySwiper ${normal ? "h-auto" : "h-full"} w-full`,
    };

    return (
        <Swiper {...swiperSettings}>
            {data?.map(item =>
                <SwiperSlide key={item.id}>

                    {type === "project" &&
                        <Item data={item} url={url} /> 
                    }

                    {type === "services" && 
                        <ParallaxService
                            title={item.title}
                            icon={item.icon}
                            content={item.content}
                        />
                    }
                        
                        
                    {type === "campagne" && 
                        <Image image={item} custWidth="lg:w-32" />
                    }
                </SwiperSlide>
            )}
        </Swiper>
    );
};

export default SwiperCustom;