import { Link } from "react-router-dom";
import { customDate } from "../utils/myDate";

export const CustomTable = ( { data, title_th } ) => {

    return (
        <table className="tables">
            <thead>
                <tr>
                    { title_th.map( (item_th, index) => 
                        <th key={index+1}> { item_th } </th>    
                    )}
                </tr>
                
            </thead>
            <tbody>
                {data &&
                    data.post.map((item, index) => (
                    <tr key={index+1}>
                        <td><Link to={`/mk-admin/mes-articles/${item.slug}`} className="link_article">{item.title}</Link></td> 
                        <td>{item.author_id.firstname} {item.author_id.lastname}</td>
                        <td> 
                            { item.categories.length === 0 || !item.categories || "" ? <span className="text">Aucun</span> :
                            item.categories.map((cat, index) => 
                                <span className="category" key={index+1}>{cat}</span>
                            )}
                        </td>
                        <td>
                            <span 
                                className="status" 
                                style={{
                                    background: item.status ? "rgba(57, 227, 101, .2)" : "rgba(227, 99, 57, .2)"
                                }}
                            >{item.status ? "Publier" : "En attente"}</span>
                        </td>
                        <td>{customDate(item.createdAt)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}