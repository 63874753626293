import { motion } from "framer-motion";
import { FaSignInAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import { FiArrowRight } from "react-icons/fi";

export const Button = ( { namebtn, primary=true, customWidth, fullWidth, path, action, type, iconlog, icon } ) => {
    const navigate = useNavigate();

    const onLink = () => {
        return navigate(`/${path}`)  
    } 
 
    return (
        <motion.button 
            className={`px-4 flex items-center justify-center gap-4 ${ primary ? "bg-gradient-to-r from-40% from-orange-400 to-rose-400" : "bg-black"} ${fullWidth ? "w-full" : customWidth ? customWidth : "max-w-52" } h-14 md:rounded-full rounded-sm flex items-center justify-center text-slate-50`} 
            onClick={ action &&  onLink } 

            whileHover={{ 
                scale: 1.045
            }}
            whileTap={{
                scale: 0.945,
            }}

            type={type}
        > 
            {iconlog && <span><FaSignInAlt /></span>} { namebtn }
            {icon && <span><FiArrowRight /></span>}
        </motion.button>
    )
}