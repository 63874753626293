import { Link, NavLink } from "react-router-dom"
import { AiOutlineDashboard, AiOutlineUser } from 'react-icons/ai';
import { GoFile } from 'react-icons/go';
import { Profil } from "./Profil";
import { Logout } from "../button/Logout";
import { FiArrowLeft } from "react-icons/fi";

export const SideMenu = () => {
    return (
        <nav className="side-menu">
            {/* <Logo color={true} size={35} /> */}
            <Link to="/" className="flex items-center gap-2 text-slate-300 hover:text-slate-400 text-2xl font-black text-center"> <span><FiArrowLeft /></span>Mobali-Lab</Link>
            <Profil />
            <ul className="list-side-menu">
                {
                    menu.map( m => 
                        <li className="item-side-menu" key={m.id}>
                            <NavLink 
                                to={m.slugmenu}
                                className={({ isActive }) => isActive ? "active" : ""}
                                end
                            ><span>{<m.icon />}</span> {m.title}
                            </NavLink>
                        </li>
                    )
                }
            </ul>
            <Logout />
            
        </nav>
    )
}

const menu = [
    {
        id:1,
        title:"Tableau de bord",
        slugmenu: "/mk-admin",
        icon: AiOutlineDashboard
    },
    {
        id:2,
        title:"Mes articles",
        slugmenu: "mes-articles",
        icon: GoFile
    },
    // {
    //     id:3,
    //     title:"Galerie",
    //     slugmenu: "#",
    //     icon: GoFileMedia
    // },
    // {
    //     id:4,
    //     title:"Apparence",
    //     slugmenu: "#",
    //     icon: MdOutlineColorLens
    // },
    {
        id:5,
        title:"Utilisateurs",
        slugmenu: "utilisateurs",
        icon: AiOutlineUser
    },
    // {
    //     id:6,
    //     title:"Réglages",
    //     slugmenu: "#",
    //     icon: AiOutlineSetting
    // }
]