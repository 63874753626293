import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../features/user/userSlice";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/`;

  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        setIsLoggedIn(false);
        setIsLoading(false);
        return;
      }

      try {
        // Vérifiez le token côté serveur
        const res = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": `${process.env.REACT_API_KEY}`,
          },
        });

        if (!res.ok) {
          throw new Error("Invalid token");
        }

        setIsLoggedIn(true);
        setIsLoading(false);

        const decodedToken = jwtDecode(token);
        dispatch(setUser(decodedToken));
      } catch (error) {
        setIsLoggedIn(false);
        setIsLoading(false);
        setError(error);
      }
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { isLoggedIn, isLoading, error };
};

export default useAuth;
