import { motion } from "framer-motion";
import { useEffect, useState, useCallback } from "react";
import Burger from "../ui/header/Burger";
import Logo from "../ui/header/Logo";
import Navbar from "../ui/header/Navbar";
import { useLenis } from "lenis/dist/lenis-react";

const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  const lenis = useLenis();

  useEffect(() => {
    if (show) {
      lenis?.stop();
    } else {
      lenis?.start();
    }
  }, [show, lenis]);
  

  // Header when scrolling
  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      // Scroll Down
      setIsVisible(false);
    } else {
      // Scroll Up
      setIsVisible(true);
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // Change background header
  const changeBack = useCallback(() => {
    setScroll(window.scrollY >= 120);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", changeBack);

    return () => {
      window.removeEventListener("scroll", changeBack);
    };
  }, [changeBack]);

  return (
    <motion.header
      id="header"
      className={`2xl:h-32 bg-red-200 h-20 fixed top-0 left-0 w-full z-50 ${scroll ? "scrolling" : ""}`}
      initial={{ y: 0 }}
      animate={{ y: isVisible ? 0 : -150 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="container w-10/12 mx-auto 2xl:h-32 h-20">
        <Logo color={true} size={35} />
        <Navbar show={show} setshow={setShow}/>
        <Burger setshow={setShow} show={show} />
      </div>
    </motion.header>
  );
};

export default Header;
