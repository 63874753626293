import ArticleComp from "./ArticleComp";
import {useContext, useEffect, useState} from "react";
import {LoadingContext} from "../context/loadingContext";
import Ping from "./atoms/Ping";

export const LoadingPage = ({ isText }) => {
    const [counter, setCounter] = useState(1);

    const { loading } = useContext(LoadingContext);

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [counter, loading]);

    return (
        <ArticleComp wrapper >
            <div className={`h-screen`}>
                <Ping isText={isText} />
            </div>
        </ArticleComp>
    );
}
