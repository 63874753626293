import {useNavigate, NavLink} from "react-router-dom"
import {useSelector} from "react-redux";
import {mydata} from "../../features/user/userSlice";import { mainMenu } from "../../utils/myData";
import { DevisButton } from "../atoms/DevisButton";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

const Navbar = ({ show, setShow }) => {

  const data = useSelector(mydata);

  const isAdmin = data?.role;

  const handleShow = () => {
    setShow(false)
  }
  
  const navigate = useNavigate();

  const handleClickAdmin = () => {
    navigate("/mk-admin")
  }

  return (
    <nav className={`navbar ${ show ? 'show' : ''}`}>
      <ul className="lg:w-auto w-10/12 mx-auto flex xl:flex-row flex-col lg:items-center justify-center 2xl:gap-10 gap-6 xl:h-auto h-screen ">
            <li className={`relative lg:hidden block`}>
              <NavLink
                onClick={() => handleShow}
                to={`/`} 
                title={`Accueil`}
                
                className={({ isActive }) =>
                  isActive ? "text-orange-400 2xl:text-2xl xl:text-sm text-4xl xl:font-medium lg:font-medium font-black" : "text-slate-50 2xl:text-2xl xl:text-sm text-4xl xl:font-medium lg:font-medium font-black"
                }
              >
                Accueil
              </NavLink>

            </li>
        {
          mainMenu.map( item => 
            <li key={item.id} className={`relative`}>
              <NavLink
                onClick={() => handleShow}
                to={`/${item.redirect_to}`} 
                title={ item.title }
            
                className={({ isActive }) =>
                  isActive ? "flex gap-2 items-center justify-center lg:font-medium font-black uppercase text-orange-400 2xl:text-2xl xl:text-sm text-4xl" : `text-slate-400 hover:text-orange-400 transition ease-linear flex items-center gap-2 2xl:text-2xl xl:text-sm text-4xl uppercase lg:font-medium font-black`
                }
              >
              <span className="lg:block hidden h-2 w-2 bg-current rounded-full"></span>{item.title}
              </NavLink>

            </li>
          )
        }
        <li className="lg:ml-8 lg:w-auto w-full">
          <DevisButton  label="Créer un devis" size="h-12" />
        </li>
        {isAdmin &&
          <li className="order-last">
            <button 
              title="Tableau de bord" 
              onClick={ handleClickAdmin }
              className="w-10 h-10 flex items-center justify-center border rounded-full bg-slate-200 hover:bg-slate-300 transition ease-in-out"
            > 
              <span className={`lg:text-2xl text-slate-900`}>
                <MdOutlineAdminPanelSettings />  
              </span>  
            </button>
          </li>
        }
      </ul>

    </nav>
  )
}

export default Navbar;