export const VideoCustom = ({videoRef, className, url, videoName}) => {
    return (

        <video
            ref={videoRef ? videoRef : null}
            muted
            loop
            autoPlay
            playsInline
            preload="metadata"
            className={`${className} w-full h-full object-cover`}
        >
            <source
                src={ url ? url : `https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/video/${videoName}.mp4`}
                type="video/mp4"
            />
            <source
                src={ url ? url : `https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/video/${videoName}.webm`}
                type="video/webm"
            />
        </video>
    );
};