import React from 'react';

const BannerPurple = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-screen">
            <figure className={`w-full h-screen`}>
                <img
                    src="/uploads/backproject2.webp"
                    alt="Fond noir avec des nuances de violet"
                    className={`object-cover w-full h-full`}
                />
            </figure>
        </div>
    )
};

export default BannerPurple;
