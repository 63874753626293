import { useDispatch } from "react-redux";
import { clearUser } from '../../features/user/userSlice';
import { FaSignOutAlt } from "react-icons/fa";
import { motion } from "framer-motion";

export const Logout = () => {
    const dispatch = useDispatch()

    const handleClickLogout = () => {
        localStorage.removeItem('token');
        dispatch(clearUser());
        window.location.replace('/mk-admin');
    }
    return (
        <motion.button 
            className="text-slate-200 hover:bg-red-400 justify-center transition-all duration-300 flex gap-2 items-center absolute bottom-0 left-0 w-full bg-red-500 h-12" 
            onClick={handleClickLogout}
            title="se déconnecter"
        > <span className="text-slate-200 text-sm group-hover:text-red-400"><FaSignOutAlt /></span> Se déconnecter
        </motion.button>
    )
}