import { List } from "./component/List";

const ActusOld = ({ limit, image, admin, home, status }) => {
  return (
    <List
      limit={limit}
      image={image}
      admin={admin}
      home={home}
      status={status}
    />
  );
};

export default ActusOld;
