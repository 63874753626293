import Heading from "../../../../ui/atoms/Heading";
import {useNavigate} from "react-router-dom";
import Image from "../../../../ui/atoms/Image";
import { motion } from "framer-motion";

const ActusItem = ({ data, index, scrollYProgress }) => {
    const navigate = useNavigate();

    const handleClick = (slug) => {
        navigate(`/actus/${slug}`)
    }

    return (
        <motion.li
        initial={{ opacity:0 }}
        animate={{ opacity:1 }}

        transition={{ type:"spring", stiffness:50, damping:10 }}
            key={data.id}
            className={`text-slate-50 relative flex flex-col gap-2 xl:w-96 md:w-6/12 w-11/12`}
        >
            <div className="flex flex-col gap-2 lg:px-0 px-4">
                <Heading content={data.title} typeHeading="h3" color="text-slate-200" size="text-md"/>
            </div>

            <div
                className="cursor-pointer"
                onClick={() => handleClick(data.slug)}
            >
                <Image
                    image={`${process.env.REACT_APP_API_URL}/uploads/${data?.picture}`}
                    alt={data.title}
                    url
                    custWidth={"xl:w-96 h-[550px]"}
                    rounded={false}
                />
            </div>
        </motion.li>
    );
};

export default ActusItem;
