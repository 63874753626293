const ArticleComp = ({ children, backgroundColor, relative= true, wrapper= true, padding, height, scrollbarHiden, style, wrapFlex }) => {
    return (
        <article
            className={`${height ? height : "max:min-h-screen"} w-full ${backgroundColor && backgroundColor} ${ scrollbarHiden && "lg:overflow-hidden"}`}
            style={style}
        >
            <div className={`${wrapper ? "wrapper" : ""} ${relative ? "relative" : ""} ${padding ? "py-10" : "py-0"} ${wrapFlex && "h-full flex items-center justify-center"}`}>
                {children}
            </div>
        </article>
    );
};

export default ArticleComp;
