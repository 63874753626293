import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { CompaignItem } from "../about/atoms/CompaignItem";
import Image from "../atoms/Image";
import Heading  from "../../ui/atoms/Heading";
import Paragraph from "../atoms/Paragraph";
import { Wire } from "../about/atoms/Wire";
import {Container} from "../atoms/Container";
import { Element } from "../about/atoms/Element";
import { FiArrowRight } from "react-icons/fi";
import { NumberIcon } from "../atoms/NumberIcon";
import { Overlay } from "../atoms/Overlay";
import useMobile from "../../hooks/useMobile";
import { VideoCustom } from "./VideoCustom";

import Modelmobali from "../../assets/images/modelmobali.webp";
import Studiocamera from "../../assets/images/studiocamera.webp";
export const HorizontalScrollCarousel = ({ data, type, light, infos, pathlink="creerundevis", cataloglink=false, labellink="Créer un devis" }) => {
    const targetRef = useRef(null);
    const isMobile = useMobile();
    
    const { scrollYProgress } = useScroll({
      target: targetRef,
    });
  
    const x = useTransform(scrollYProgress, [0, 1], isMobile ? ["0%", "0%"] : ["1%", "-80%"]);
    
    return (
      <div className="relative w-full h-auto">

          <Container className="flex flex-col items-center gap-4 py-20 relative overflow-hidden">
            <div className="flex lg:flex-row flex-col items-center gap-10">
              
              <div className="relative flex flex-col items-center">
                <Wire />
                <Element title="Émotion" rotate={5} />
              </div>

              <div className="relative flex flex-col items-center">
                <Wire />
                <Element title="x" rounded={true} />
              </div>

              <div className="relative flex flex-col items-center">
                <Wire />
                <Element title="Data" rotate={-5} />
              </div>

              <div className="relative flex flex-col items-center">
                <Wire />
                <Element title="=" rounded={true} />
              </div>

              <div className="relative flex flex-col items-center">
                <Wire />
                <Element title="Résultats Durables" rotate={5}/>
              </div>

            </div>
          </Container>

          <div ref={targetRef} className="relative lg:h-[700vh] min-h-screen">
            <div className="lg:sticky left-0 top-0 flex lg:h-screen h-full items-center overflow-hidden">
              <motion.ul 
                style={{ x }} 
                className="flex lg:w-auto w-10/12 mx-auto lg:flex-row flex-col lg:gap-4 gap-20 lg:pl-14 pl-0 items-center"
              >
                

                { type === "campaign" ? 
                  <>
                    {/* Etape 1 */}
                    <li className="xl:w-[150vh] lg:w-[110vh] flex lg:flex-row flex-col items-stretch relative gap-10 lg:gap-0">
                      <div className="lg:w-1/3 w-full grow relative">

                        <div className="xl:max-w-xl lg:max-w-md w-full lg:absolute left-10 bottom-20 flex flex-col gap-2 2xl:gap-8">
                          <Heading content="Décryptage & Stratégie" primary color size="2xl:text-7xl lg:text-5xl text-2xl" font="font-black" />
                          <Paragraph content="Avant toute chose, nous analysons en profondeur votre marque et votre audience pour poser les bases d'une campagne efficace." />
                          <NumberIcon number={1} />
                        </div>
                        
                      </div>
                      <div className="lg:w-[70vh] w-full lg:h-[80vh] h-[70vh] rounded-2xl overflow-hidden">
                        <Image image="/uploads/openspace.webp" url custWidth="w-full h-full" rounded={false} />
                      </div>

                    </li>
                    
                    {/* Etape 2 */}
                    <li className="lg:w-screen w-full flex flex-col gap-20 items-center relative">
                      <div className="lg:w-9/12 w-full flex items-end justify-between gap-10">
                        <div className="z-10 max-w-xl flex flex-col gap-2 2xl:gap-8">
                          <Heading primary={false} content="Sourcing & Casting des Influenceurs" size="2xl:text-7xl lg:text-5xl text-2xl" font="font-black" />
                          <Paragraph primary={false} content="Nous sélectionnons des talents en fonction de leur audience, engagement et adéquation avec votre image." />
                          <NumberIcon number={2} />
                        </div>
                        <span className="lg:block hidden text-4xl text-slate-700"><FiArrowRight /></span>
                      </div>
                        
                      <div className="w-full scrollbar-hidden lg:overflow-auto overflow-x-auto flex gap-4 grow lg:order-first order-last">
                        <div className="flex gap-4 items-center justify-center mx-auto whitespace-nowrap">
                          <div className="w-60 lg:h-[30rem] lg:w-80 2xl:h-[60rem] h-96 rounded-2xl overflow-hidden">
                            <VideoCustom videoName="impulstar" />
                          </div>
                          <div className="w-60 lg:h-[30rem] lg:w-80 2xl:h-[60rem] h-96 rounded-2xl overflow-hidden">
                            <VideoCustom videoName="lipaulxcamerstyle" />
                          </div>
                          <div className="w-60 lg:h-[30rem] lg:w-80 2xl:h-[60rem] h-96 rounded-2xl overflow-hidden">
                            <Image image="/uploads/project/ismafeatpuma.webp" url custWidth="w-full h-full" rounded={false} />
                          </div>
                          <div className="w-60 lg:h-[30rem] lg:w-80 2xl:h-[60rem] h-96 rounded-2xl overflow-hidden">
                            <VideoCustom videoName="baya" />
                          </div>
                          
                        </div>
                      </div>
                    </li>

                    {/* Etape 3 */}
                    <li className="lg:w-screen w-full flex flex-col lg:flex-row items-stretch">
                      <div className="lg:w-fit w-full scrollbar-hidden lg:overflow-auto overflow-x-auto flex gap-4 grow lg:order-first order-last">
                        <div className="flex gap-4 items-center justify-center mx-auto whitespace-nowrap">
                          <div className="w-60 lg:h-[30rem] lg:w-80 2xl:h-[60rem] h-96 rounded-2xl overflow-hidden">
                              <VideoCustom 
                                videoName="influemakevideo"
                              />
                          </div>
                          <div className="w-60 lg:h-[30rem] lg:w-80 2xl:h-[60rem] h-96 rounded-2xl overflow-hidden">
                            <VideoCustom 
                              videoName="influemakevideo2"
                            />
                          </div>
                        </div>
                        
                      </div>
                      

                      <div className="w-full lg:w-1/2 relative lg:order-last order-first">
                        <div className="max-w-xl lg:absolute mb-10 lg:mb-0 left-10 bottom-20 flex flex-col gap-2 2xl:gap-8">
                          <Heading content="Création de Contenus Engageants" primary color size="2xl:text-7xl lg:text-5xl text-2xl" font="font-black" />
                          <Paragraph content="Nos créateurs bénéficient de nos studios professionnels pour produire des campagnes de haute qualité." />
                          <NumberIcon number={3} />
                        </div>
                      </div>
                    </li>

                    {/* Etape 4 */}
                    <li className="lg:w-[150vh] w-full flex items-stretch lg:ml-32 relative">
                    
                        <div className="z-10 max-w-xl absolute lg:left-20 left-10 bottom-32 flex flex-col gap-2">
                          <Heading content="Activation & Diffusion Multicanale" size="2xl:text-7xl lg:text-5xl text-2xl" font="font-black" />
                          <Paragraph primary content="Nous amplifions la portée des contenus en combinant organique et sponsorisation." />
                          <NumberIcon number={4} />
                        </div>
                    
                      
                      <div className="w-full lg:h-[90vh] h-[70vh] relative rounded-3xl overflow-hidden">
                        
                        <Image image={Studiocamera} url custWidth="w-full h-full" rounded={true} />
                        <Overlay />
                      </div>

                    </li>

                    {/* Etape 5 */} 
                    <li className="lg:w-screen w-full flex lg:flex-row flex-col gap-4 items-center justify-center">
                      <div className="lg:w-[80vh] w-full lg:h-[100vh] h-[70vh] lg:rounded-none rounded-3xl overflow-hidden">
                        <Image image={Modelmobali} url custWidth="w-full h-full" rounded={false} />
                      </div>
                      <div className="w-full lg:w-1/2 relative lg:order-last order-first">
                        <div className="max-w-xl flex flex-col gap-2">
                          <Heading content=" Pilotage & Optimisation en Temps Réel" primary color size="2xl:text-7xl lg:text-5xl text-2xl" font="font-black" />
                          <Paragraph content="Nous analysons en continu les performances pour ajuster la campagne et maximiser le ROI." />
                          <NumberIcon number={5} />
                        </div>
                      </div>
                    </li>

                  </>

                  :

                  data?.map((item, index) => 
                    <CompaignItem type={type} item={item} uniqueKey={index+1} />
                  )
                }
              </motion.ul>
            </div>
            
        </div>
      
      </div>
    );
  };        