import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import Mobali from "../../assets/images/mobali.webp"; 
import Right from "../../assets/images/right.webp";
import Left from "../../assets/images/left.webp";

export const AboutSlv = () => {
    const ref = useRef();

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "start center"] // L'animation commence dès que l'élément entre dans la vue
    });

    // Animation de l'image (Parallax + Fade In + Scale)
    const y = useTransform(scrollYProgress, [0, 1], [100, 0]); // Parallax
    const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]); // Fade In
    const scale = useTransform(scrollYProgress, [0, 1], [1.1, 1]); // Scale léger

    return (
        <motion.div 
            ref={ref} 
            className="flex border-t border-slate-400 gap-10 relative w-full items-stretch h-screen"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
        >
            {/* Bandes oranges animées */}
            <motion.div 
                className="w-1/3 lg:block hidden" 
                style={{ opacity, y }}
            > 
                <figure className="lg:w-[45rem] lg:h-[50rem] overflow-hidden">
                    <motion.img 
                        src={Left} 
                        alt="Responsable de la Mobali : Sylvain Nzuzi" 
                        className="w-full h-full object-cover"
                        style={{ y, opacity, scale }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    />
                </figure>
            </motion.div>
            
            {/* Conteneur de l'image */}
            <motion.div 
                className="lg:w-1/3 grow lg:h-[50rem]"
                style={{ y, opacity }}
            >
                <figure className="lg:w-[45rem] lg:h-[50rem] overflow-hidden">
                    <motion.img 
                        src={Mobali} 
                        alt="Responsable de la Mobali : Sylvain Nzuzi" 
                        className="w-full h-full object-cover"
                        style={{ y, opacity, scale }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    />
                </figure>
            </motion.div>

            <motion.div 
                className="w-1/3 grow lg:block hidden" 
                style={{ opacity, y }}
            > 
                <figure className="lg:w-[45rem] lg:h-[50rem] overflow-hidden">
                    <motion.img 
                        src={Right} 
                        alt="Responsable de la Mobali : Sylvain Nzuzi" 
                        className="w-full h-full object-cover"
                        style={{ y, opacity, scale }}
                        transition={{ duration: 1, ease: "easeOut" }}
                    />
                </figure>
            </motion.div>
        </motion.div>
    );
};