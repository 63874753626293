import { allPhotosUrl } from "../../utils/myData";
import Image  from "../atoms/Image";
import { useNavigate } from "react-router-dom";
export const Galerie = () => {

    const [firstImage, ...restImages] = allPhotosUrl;
    const firstFourImages = restImages.slice(0, 4);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/lab/galeries`);
    }

    return (
        <div className="flex lg:flex-row flex-col gap-2 items-stretch 2xl:h-[70vh] lg:h-[600px] h-auto">
            <div className="w-full lg:w-1/2 lg:h-full h-96" onClick={handleClick}>
                <Image
                    custWidth={`h-full w-full cursor-pointer`}
                    image={`https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/images/${firstImage.mainImage}.webp`}
                    url
                    rounded={false}
                />
            </div>
            <div onClick={handleClick} className="bg-green-50 w-full lg:w-1/2 lg:flex flex-col h-full">
                <ul className="flex lg:h-1/2 h-52 lg:mb-0 mb-8">
                    {firstFourImages.slice(0,2).map(image => (
                        <li key={image.id} className="w-1/2">
                            <Image
                                custWidth={`h-full w-full cursor-pointer`}
                                image={`https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/images/${image.mainImage}.webp`}
                                url
                                rounded={false}
                            />
                        </li>
                    ))}
                </ul>
                <ul className="flex lg:h-1/2 h-52">
                    {firstFourImages.slice(2,4).map(image => (
                        <li key={image.id} className="w-1/2">
                            <Image
                                custWidth={`h-full w-full cursor-pointer`}
                                image={`https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/images/${image.mainImage}.webp`}
                                url
                                rounded={false}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}