import HeadCustom from "../../../HeadCustom";
import {useContext, useEffect} from "react";
import {LoadingContext} from "../../../context/loadingContext";
import Blog from "../component/organisms/Blog";
import {Brand} from "../../../ui/admin/Brand";
import Social from "../../../ui/organisms/social";
import BannerPurple from "../../../ui/atoms/BannerPurple";
import {LoadingPage} from "../../../ui/LoadingPage";
import { HeroText } from "../../../ui/text/HeroText";
import { useLenis } from "lenis/dist/lenis-react";

const Actus = () => {
    const { loading, startLoading } = useContext(LoadingContext);

    useEffect(() => {
        startLoading();
    }, [startLoading]);

    const lenis = useLenis();
    
    useEffect(() => {
        if (lenis && lenis._isStopped) {
            lenis.start();
        }
    }, [lenis]);

   if (loading) return <LoadingPage isText="Actus en cours.." />;

    return (
        <>
        
            <HeadCustom headtitle={"Mobali-Lab Actualités"} />
            <article className="min-h-screen flex flex-col xl:gap-0 gap-4">
                <Blog />

                <div className={`xl:fixed xl:w-auto md:w-6/12 w-11/12 xl:py-0 py-4 m-auto top-24 left-20 z-10 flex flex-col items-start gap-4 order-first`}>
                    <HeroText content="Ce Qui Nous Inspire : L'Actualité Mobali-Lab en Un Clic" className="order-last xl:max-w-md"/>
                    <Brand />
                </div>

                <div className="xl:fixed bottom-10 left-20 z-10 flex flex-col xl:items-center xl:w-auto md:w-6/12 w-11/12 m-auto gap-4 order-first">
                    <Social col />  
                </div>

                <BannerPurple />
            </article>        
        </>

    );
};

export default Actus;