import { ParallaxCont } from "../about/atoms/ParallaxCont";
import { CenterImage } from "../studio/atoms/CenterImage"


export const CoverParallax = ({ cover, listText, order=false }) => {

    const SECTION_HEIGHT = 1000;

    return (
        <div 
            className={`relative w-full bg-black ${ order && "order-first"}`}
            style={{ height: `calc(${SECTION_HEIGHT}px + 100vh)`}}
        >
            <CenterImage cover={cover} />

            { listText?.map( item => 
                <ParallaxCont 
                    start={item.animation.start}
                    end={item.animation.end}
                    className="mx-auto text-slate-200 xl:w-6/12 lg:w-1/2 w-11/12 font-bold text-center italic"
                    item={item}
                />
            )}
            
            
            <div className="absolute bottom-0 left-0 right-0 h-96 bg-gradient-to-b from-zinc-950/0 to-zinc-950" />
        </div>
    )
}