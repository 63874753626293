import useFetch from "../../hooks/useFetch"
import { FiMinimize } from 'react-icons/fi';

export const SideBox = () => {

    const { data } = useFetch(`${process.env.REACT_APP_API_URL}/api/posts`);

    const filteredPosts = data && data.post.filter( post => post.status === true);

    console.log(filteredPosts);
    
    const dataCard = [
        {
            id: 1,
            title: "Actualites",
            nb__: data && data.postLength
        },
        {
            id: 2,
            title: "Actus en attente",
            nb__: filteredPosts && filteredPosts.length
        }
    ]
    return (
        <div>
             <ul className="flex flex-col gap-4 h-96">
                {data &&
                    dataCard.map( card =>          
                    <li key={card.id} className="relative flex flex-col items-start overflow-hidden justify-center gap-2 border px-10 rounded-2xl h-32 bg-slate-100">
                        <p className="text-slate-700 text-lg font-normal text-right">{card.title}</p>
                        <p className="text-orange-400 text-xl font-medium">{card.nb__}</p>
                        <span className="text-orange-400/70 text-6xl absolute -top-2 -right-4 rotate-45"><FiMinimize /></span>
                    </li>
                    )
                }  
            </ul>
        </div>
       
    )
}