import { motion } from "framer-motion"
import Paragraph from "../ui/atoms/Paragraph";
import { FiArrowRight, FiChevronLeft } from "react-icons/fi";
import { allPhotosUrl } from "../utils/myData";
import Image from "../ui/atoms/Image";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { LoadingContext } from "../context/loadingContext";
import { LoadingPage } from "../ui/LoadingPage";

export const Galeries = () => {
  const navigate = useNavigate();

  const { loading, startLoading } = useContext(LoadingContext);

    useEffect(() => {
        startLoading();
    }, [startLoading]);

  // Diviser les données en groupes de 3
  const groupedPhotos = [];
  const groupSize = 3;

  for (let i = 0; i < allPhotosUrl.length; i += groupSize) {
    groupedPhotos.push(allPhotosUrl.slice(i, i + groupSize));
  }

  if (loading) return <LoadingPage isText="Lab en cours ..." />;

  return (
    <motion.article
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 15 }}
      className="relative min-h-screen bg-slate-50"
    >
      {/* Header */}
      <div className="relative flex items-center justify-center bg-slate-50 shadow-sm shadow-slate-300">
        <ul className="h-20 flex items-center justify-between w-11/12">
          <li>
            <button
              onClick={() => navigate("/lab")}
              className="flex items-center gap-2"
            >
              <span>
                <FiChevronLeft />
              </span>
              Revenir
            </button>
          </li>
          <li>
            <ul className="flex items-center gap-0 md:divide-x divide-slate-400">
              <li className="px-2 md:block hidden">
                <Paragraph
                  content="27 Digue d'Alforville, 92700"
                  size="Small"
                  color="text-slate-700"
                />
              </li>
              <li className="px-2">

                <Link
                    to={`/creerundevis`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-orange-400 xl:max-w-96 lg:max-w-52 flex items-center gap-2 cursor-pointer group hover:text-orange-200 2xl:text-2xl text-sm transition ease-in-out duration-75"
                    >
                    Reserver
                    <span className="group-hover:translate-x-4 transition ease-in-out duration-200 text-orange-400">
                        <FiArrowRight />
                    </span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      {/* Galerie */}
      <div className="py-10 flex justify-center">
        <ul className="grid lg:grid-cols-3 md:grid-cols-2 w-11/12 lg:gap-20 gap-10">
          {groupedPhotos.map((group, groupIndex) => (
            <li key={groupIndex}>
              <ul className="flex flex-col gap-4">
                {group.map((item) => (
                  <li
                    key={item.id}
                    className="flex items-center justify-center"
                  >
                    <Image
                      image={`https://pub-8f46a5feeb644ccfb14abd23763d9542.r2.dev/images/${item.mainImage}.webp`}
                      alt={item.alt}
                      url
                      custWidth="xl:w-full md:w-72 w-full"
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </motion.article>
  );
};