import LogoMobaliParis from "../../assets/images/logo.webp";
import {useNavigate} from "react-router-dom";

export const Brand = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    }

    return (
    <div className="h-14 w-14 rounded-full bg-slate-200 cursor-pointer" onClick={handleClick}>
        <figure className={`h-14 w-14`}>
          <img src={LogoMobaliParis} alt="Logo du site" className={`w-full h-full object-cover rounded-full`} />
        </figure>
    </div>
  );
};
